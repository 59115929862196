import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
    width: 25%;
    position: absolute;
    top: 20%;
    transform: translateX(-50%);
    left: 50%;

    & .signin-link {
        display: inline;
    }
`;

export const InputContainer = styled.div`
    border: 1px solid black;
    height: 60px;
    position: relative;
    border-radius: 4px;
    margin-bottom: 30px;
`;

export const InputLabelWrapper = styled.span`
    position: absolute;
    top: -7px;
    left: 10px;
    padding: 0px 5px;
    background-color: white;
`;

export const StyledInput = styled.input`
    &:focus {
        border: 1px solid black !important;
    }
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
`;

export const Gap = styled.div<{ y: number }>`
    height: ${(p) => p.y}px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const StyledModalBody = styled(Modal.Body)`
    border-radius: 8px;
    text-align: center;
`;
