import { FC } from 'react';

import { Button, Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { InputProps } from 'src/types/resetPassword';
import { Colors } from 'src/utils/color';

import { InputContainer, InputLabelWrapper, StyledInput } from './styled';

export const Input: FC<InputProps> = (props) => {
    const { label, value, onChange, touched, error } = props;
    return (
        <InputContainer>
            <InputLabelWrapper>
                <Text
                    fontFamily='"Inter"'
                    fontSize={Size.XXXSmall}
                    lineHeight="12px"
                    marginBottom={0}
                >
                    {label}
                </Text>
            </InputLabelWrapper>
            <StyledInput type="password" value={value} onChange={onChange} />
            {touched && error && <ErrorText text={error} />}
        </InputContainer>
    );
};

export const ErrorText: FC<{ text: string }> = ({ text }) => (
    <Text marginBottom={0} color={Colors.extra.red}>
        {text}
    </Text>
);

export const CancelButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
        backgroundColor={Colors.theme.secondary}
        borderColor={Colors.theme.secondary}
        width="126px"
        height="42px"
        fontSize={14}
        fontWeight={600}
        color={'black'}
        borderRadius="4px"
        onClick={onClick}
    >
        Cancel
    </Button>
);

export const SaveButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
        width="126px"
        height="42px"
        fontSize={14}
        fontWeight={600}
        color={'black'}
        borderRadius="4px"
        onClick={onClick}
        margin="0 0 0 8px"
        background={Colors.change_password_modal.save_button}
        type="submit"
    >
        Save
    </Button>
);

export const ResetPasswordText: FC = () => (
    <Text
        fontFamily='"Inter"'
        fontWeight="600"
        fontSize={Size.XSmall}
        lineHeight="16px"
        marginLeft={10}
    >
        Reset your password
    </Text>
);

export const PasswordSetText: FC = () => (
    <>
        <Text
            fontFamily='"Inter"'
            fontWeight="700"
            fontSize={Size.XXXSmall}
            marginBottom={0}
        >
            Your password was set successfully.
        </Text>
        <Text
            fontFamily='"Inter"'
            fontWeight="700"
            fontSize={Size.XXXSmall}
            marginBottom={0}
            display="inline"
        >
            Please{' '}
        </Text>
        <Text
            textDecorationLine="underline"
            fontFamily='"Inter"'
            fontWeight="400"
            fontSize={Size.XXSmall}
            marginBottom={0}
            display="inline"
            onClick={() => (window.location.href = '/')}
            cursor="pointer"
        >
            sign in
        </Text>
    </>
);

export const ThankyouText: FC = () => (
    <>
        <Text
            fontFamily='"Inter"'
            fontSize={Size.X4Small}
            marginBottom={0}
            marginTop={20}
            textAlign="left"
            paddingLeft={30}
        >
            Thank you,
        </Text>
        <Text
            fontFamily='"Inter"'
            fontSize={Size.X4Small}
            marginBottom={0}
            textAlign="left"
            paddingLeft={30}
        >
            The C-Light team
        </Text>
    </>
);
