import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { Colors } from 'src/utils/color';

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
`;

const Divider = styled.hr`
    border: none;
    height: 0.5px;
    background-color: ${Colors.extra.light_black};
    margin: 0;
`;

const RightContent = styled.div`
    margin-right: 8px;
    min-width: 70px;
    margin-left: 16px;
`;

interface Props {
    rightContent: string;
    leftContent: string;
    padding?: number | undefined;
}

const ListItems: FunctionComponent<Props> = ({
    rightContent,
    leftContent,
    padding
}: Props) => {
    return (
        <React.Fragment>
            <Row>
                <Text
                    fontWeight="500"
                    color={Colors.extra.black}
                    fontSize={Size.XXSmall}
                    padding={padding}
                    marginTop={5}
                    marginBottom={0}
                >
                    {leftContent}
                </Text>
                <RightContent>
                    <Text
                        fontWeight="700"
                        color={Colors.theme.primary}
                        fontSize={Size.XXSmall}
                        padding={padding}
                        marginTop={5}
                        marginBottom={0}
                    >
                        {rightContent}
                    </Text>
                </RightContent>
            </Row>
            <Divider />
        </React.Fragment>
    );
};

export default ListItems;
