import RDP from 'react-datepicker';
import styled from 'styled-components';

import { ReactComponent as ArrowDown } from 'src/assets/icons/arrow-down.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/clock.svg';
import { Colors } from 'src/utils/color';

export const Container = styled.div`
    position: relative;
    display: inline-block;
`;

export const Button = styled.div`
    background-color: ${Colors.dropdown.dropdown_bg};
    gap: 18px;
    border-radius: 100px;
    color: ${Colors.theme.primary};
    font-weight: 700;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

export const ButtonText = styled.span`
    color: ${Colors.date_picker.select_txt};
    font: 700 12px/18px 'Inter', sans-serif;
`;

export const StyledArrowDown = styled(ArrowDown)`
    margin-left: 30px;
`;

export const Modal = styled.div<{ visible: Boolean }>`
    width: 454px;
    height: 290px;
    background-color: ${Colors.theme.secondary};
    position: absolute;
    right: 0;
    margin-top: 8px;
    border-radius: 4px;
    z-index: 1;
    display: ${(p) => (p.visible ? 'flex' : 'none')};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const Presets = styled.div`
    width: 135px;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StyledClockIcon = styled(ClockIcon)`
    margin: 7px 0px 3px 44px;
`;

export const Preset = styled.span<{ selected: Boolean }>`
    padding: 7px 10px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.date_picker.preset_bg};
    font: 500 12px/15px 'Inter', sans-serif;
    color: ${(p) =>
        p.selected ? Colors.theme.primary : Colors.date_picker.preset_txt};
    margin-top: 10px;
    width: max-content;
    margin-left: 25px;
    cursor: pointer;
`;

export const Custom = styled.div`
    height: 100%;
    width: 331px;
    display: flex;
    flex-direction: column;
`;

export const Selector = styled.div`
    height: 234px;
    display: flex;
    width: 329px;
`;

export const Actions = styled.div`
    height: 56px;
    width: 329px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const Clear = styled.div`
    width: 59px;
    height: 28px;
    background-color: ${Colors.date_picker.clear_bg};
    border-radius: 9px;
    font: 500 12px/12px 'Inter', sans-serif;
    color: ${Colors.date_picker.clear_txt};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const Apply = styled.div`
    width: 61px;
    height: 28px;
    margin-left: 6px;
    font: 500 12px/12px 'Inter', sans-serif;
    color: ${Colors.extra.white};
    background-color: ${Colors.date_picker.apply_bg};
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
`;

export const Calendar = styled.div<{ datesPadding: number }>`
    width: 238px;
    height: 215px;
    align-self: flex-end;
    border-top: 1px solid ${Colors.date_picker.calendar_border};
    border-bottom: 1px solid ${Colors.date_picker.calendar_border};
    overflow: hidden;

    & .react-datepicker {
        border: none;
    }

    & .react-datepicker__header {
        border-bottom: none;
    }

    & .react-datepicker__day-name {
        font: 500 10px/12px 'Inter', sans-serif;
        margin-bottom: 4px;
        margin-top: 2px;
    }

    & .react-datepicker__day {
        font: 10px/12px 'Inter', sans-serif;
        padding-top: ${(p) => p.datesPadding}px;
        padding-bottom: ${(p) => p.datesPadding}px;
    }
`;

export const Range = styled.div`
    width: 73px;
    height: 215px;
    align-self: flex-end;
    background: linear-gradient(
        180deg,
        rgba(248, 248, 248, 0.2) 17.91%,
        #f8f8f8 49.88%,
        rgba(248, 248, 248, 0.2) 81.86%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border-top: 1px solid ${Colors.date_picker.calendar_border};
    border-bottom: 1px solid ${Colors.date_picker.calendar_border};
`;

export const Month = styled.span<{ selected: Boolean }>`
    font-family: 'Inter', sans-serif;
    padding: 10px 0px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-top: ${(p) => p.selected && '1px solid black'};
    border-bottom: ${(p) => p.selected && '1px solid black'};
    width: 50%;
    text-align: center;
    cursor: pointer;
    line-height: 12px;
    color: ${(p) => (p.selected ? 'black' : Colors.date_picker.month_txt)};
    font-size: ${(p) => (p.selected ? 14 : 12)}px;
    font-weight: ${(p) => (p.selected ? 500 : 400)};
`;

export const Day = styled.div<{
    hidden?: Boolean;
    fontWeight?: number;
    cursor?: string;
}>`
    display: inline-block;
    width: 34px;
    text-align: center;
    height: 29px;
    visibility: ${(p) => p.hidden && 'hidden'};
    font: 10px/12px 'Inter', sans-serif;
    font-weight: ${(p) => p.fontWeight};
    cursor: ${(p) => p.cursor};
`;

export const StyledRDP = styled(RDP)`
    & .rdp-calendar {
        display: none;
    }
`;

export const Popper = styled.div`
    position: relative;
`;

export const StyledCalendar = styled.div`
    position: relative;
    bottom: 33px;
    border-radius: 0px;
    & .react-datepicker__month-container,
    & .react-datepicker__header {
        background-color: ${Colors.theme.secondary};
        border-left: none;
    }
`;
