import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg';
import { ReactComponent as HealthMonitorIcon } from 'src/assets/icons/healthMonitor.svg';
import LogoIcon from 'src/assets/icons/logoNavBar.png';
import { ReactComponent as PlayIcon } from 'src/assets/icons/play.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg';
import { ReactComponent as UserIcon } from 'src/assets/icons/userIcon.svg';
import { UserSelectors } from 'src/reducers/user';
import { clearAll } from 'src/services/sessionStorage';
import { Size } from 'src/shared/text';
import { clearAuthToken } from 'src/utils/auth';
import { Colors } from 'src/utils/color';

import {
    ChangePasswordModal,
    HealthMonitorModal,
    UploadVideoModal
} from './components';
import {
    Content,
    ContentWrapper,
    EmptyView,
    IconButton,
    IconStyle,
    Icons,
    Item,
    LeftBarContainer,
    Menu,
    NavIconWrapper,
    PlayIconWrapper,
    RightNavWrapper,
    SettingMenu,
    Text,
    Topbar,
    Wrapper
} from './styles';
import { isCLightUser } from 'src/utils/utils';

type Props = {
    children?: React.ReactNode;
};

const menuOption = [
    'Account',
    'Notification',
    'Help center',
    'Change your password'
];

const DashboardWrapper: FunctionComponent<Props> = ({ children }: Props) => {
    const navigate = useNavigate();
    const modalRef = useRef<any>(null);
    const settingRef = useRef<any>(null);
    const healthModalRef = useRef<any>(null);
    const [menu, setMenu] = useState<boolean>(false);
    const [settingMenu, setSettingMenu] = useState<boolean>(false);
    const [csvLink] = useState<string>(
        'https://sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv'
    );
    const { profile } = UserSelectors();
    const isClightUser = isCLightUser(profile);

    const onSettingItemClick = useCallback(
        (option: string) => {
            setSettingMenu(false);
            if (option === 'Change your password') {
                settingRef?.current?.show();
            }
        },
        [settingRef]
    );

    const tooltip = (label: string) => {
        return <Tooltip id="tooltip">{label}</Tooltip>;
    };

    const showSettingMenu = () => {
        setSettingMenu(true);
    };

    const hideSettingMenu = () => {
        setSettingMenu(false);
    };

    return (
        <>
            <Wrapper>
                <Topbar>
                    <NavIconWrapper />
                    <Icons src={LogoIcon} />
                    <RightNavWrapper>
                        <UserIcon style={IconStyle} />
                        <Text
                            fontWeight="700"
                            fontSize={Size.XXSmall}
                            color={Colors.extra.white}
                            lineHeight="25px"
                            marginBottom={0}
                            onClick={() => setMenu(!menu)}
                        >
                            {profile?.first_name + ' ' + profile?.last_name}
                        </Text>
                    </RightNavWrapper>
                    <Menu isOpen={menu}>
                        <Item
                            onClick={() => {
                                clearAuthToken();
                                clearAll();
                                setMenu(false);
                                navigate('/');
                            }}
                        >
                            <Text
                                color={Colors.theme.primary}
                                marginRight={0}
                                fontFamily="Inter"
                                fontSize={12}
                                lineHeight={'15'}
                                fontWeight="500"
                                textAlign="center"
                                width="100%"
                                padding={3}
                            >
                                Log out
                            </Text>
                        </Item>
                    </Menu>
                </Topbar>
                <ContentWrapper>
                    <LeftBarContainer>
                        {isClightUser && (
                        <>
                        <IconButton
                            onClick={() => healthModalRef.current.show()}
                        >
                            <HealthMonitorIcon />
                        </IconButton>
                        <IconButton onClick={() => modalRef.current.show()}>
                            <PlayIconWrapper>
                                <PlayIcon />
                            </PlayIconWrapper>
                        </IconButton>
                        <IconButton
                            href={csvLink}
                            onMouseEnter={hideSettingMenu}
                        >
                            <OverlayTrigger
                                placement="right"
                                overlay={tooltip('Vasculature Analytics')}
                            >
                                <EyeIcon />
                            </OverlayTrigger>
                        </IconButton>
                        </>
                        )}
                        <IconButton onMouseEnter={showSettingMenu}>
                            <SettingsIcon />
                        </IconButton>
                        <SettingMenu
                            onMouseLeave={hideSettingMenu}
                            isOpen={settingMenu}
                        >
                            {menuOption.map((item: string) => {
                                return (
                                    <Item
                                        onClick={() => onSettingItemClick(item)}
                                    >
                                        <Text
                                            color={Colors.theme.primary}
                                            marginRight={0}
                                            fontFamily="Inter"
                                            fontSize={12}
                                            fontWeight="500"
                                            textAlign="center"
                                            width="100%"
                                            padding={3}
                                        >
                                            {item}
                                        </Text>
                                    </Item>
                                );
                            })}
                        </SettingMenu>
                        <EmptyView onMouseEnter={hideSettingMenu} />
                    </LeftBarContainer>
                    <Content onMouseEnter={hideSettingMenu}>{children}</Content>
                </ContentWrapper>
            </Wrapper>
            <UploadVideoModal
                ref={modalRef}
                onHide={() => modalRef?.current?.hide()}
            />
            <HealthMonitorModal ref={healthModalRef} />
            <ChangePasswordModal
                ref={settingRef}
                onSave={(data) => {
                    settingRef?.current?.hide();
                }}
            />
        </>
    );
};

export default DashboardWrapper;
