import React, {
    FunctionComponent,
    forwardRef,
    useImperativeHandle,
    useState
} from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';

import { UploadContainer, UploadItem } from '../';
import { Button } from 'src/shared';
import { Size } from 'src/shared/text';
import { Colors } from 'src/utils/color';

type Props = ModalProps & {
    onSend?: () => void;
};

const UploadVideoModal: FunctionComponent<Props> = forwardRef<any, Props>(
    (props, ref) => {
        const { onSend, ...modalProps } = props;

        const [show, setShow] = useState(false);

        useImperativeHandle(ref, () => ({
            show: () => {
                setShow(true);
            },
            hide: () => {
                setShow(false);
            }
        }));

        const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

        const handleSelect = (fileName: string) => {
            setSelectedFiles((prevSelectedFiles) => [
                ...prevSelectedFiles,
                fileName
            ]);
        };

        const handleRemove = (fileName: string) => {
            setSelectedFiles((prevSelectedFiles) =>
                prevSelectedFiles.filter((file) => file !== fileName)
            );
        };

        return (
            <Modal
                {...modalProps}
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header style={styles.header}>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={styles.title}
                    >
                        <UploadContainer onSelect={handleSelect} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={styles.modalBg}>
                    <div>
                        {selectedFiles.map((fileName, index) => (
                            <div style={styles.uploadItems}>
                                <UploadItem
                                    key={index}
                                    title={fileName}
                                    progress={10}
                                    status="pending"
                                    totalUnit="10kb"
                                    onRemove={() => handleRemove(fileName)}
                                />
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer style={styles.footer}>
                    <Button
                        onClick={props.onHide}
                        backgroundColor={Colors.theme.secondary}
                        borderColor={Colors.theme.secondary}
                        borderRadius="4px"
                        color={Colors.extra.black}
                        fontSize={Size.XXSmall}
                        fontWeight={600}
                        width="126px"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onSend}
                        backgroundColor={Colors.theme.primary}
                        borderColor={Colors.theme.primary}
                        borderRadius="4px"
                        color={Colors.extra.black}
                        fontSize={Size.XXSmall}
                        fontWeight={600}
                        width="126px"
                    >
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
);

export default UploadVideoModal;

const styles: { [key: string]: React.CSSProperties } = {
    modalBg: {
        background: `${Colors.extra.modal_bg}`,
        border: `1px solid ${Colors.extra.modal_bg}`,
        borderRadius: '0px',
        overflowY: 'auto',
        height: '38vh',
        padding: '50px 30px',
        position: 'relative'
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        background: `${Colors.extra.modal_bg}`,
        border: `1px solid ${Colors.extra.modal_bg}`,
        borderRadius: '0px'
    },
    header: {
        background: `${Colors.extra.modal_bg}`,
        border: `1px solid ${Colors.extra.modal_bg}`,
        borderRadius: '0px',
        padding: '40px 30px 0px 30px'
    },
    title: {
        width: '100%',
        textAlign: 'center'
    },
    uploadItems: {
        padding: '30px 20px 0px 20px'
    }
};
