import { FC, useState } from 'react';
import DT from 'react-data-table-component';

import { Text } from 'src/shared';
import { DataRow, DataTableProps } from 'src/types/dataTable';

import { CustomPagination } from './components';
import { Container, EmptyTable } from './styled';

/**
 * @example
 *    <DataTable
 *        columns={columns}
 *        data={data}
 *        perPageLimit={10}
 *        onChangePage={(page, totalRows) => {}}
 *    />
 *    columns = [
 *        { name: 'User', selector: (row) => row.user, width: '9%', center: true },
 *        { name: 'Subject', selector: (row) => row.subject },
 *    ];
 *    data = [
 *        { user: '214FR4', subject: 'Nov 6, 2022 7:15 AM' },
 *        { user: '214FR4', subject: 'Nov 6, 2022 7:15 AM' }
 *    ];
 */
const CustomNoDataComponent = () => (
    <EmptyTable className="custom-no-data">
        <Text fontWeight="600" marginTop={0} marginBottom={0}>
            There are no records to display
        </Text>
    </EmptyTable>
);
const DataTable: FC<DataTableProps<DataRow>> = (props) => {
    const {
        columns,
        data,
        perPageLimit,
        onChangePage,
        onRowClicked,
        onChangeRowsPerPage,
        paginationTotalRows,
        key
    } = props;

    const [page, setPage] = useState(1);

    const onHandlehangePage = (cPage: number) => {
        setPage(cPage);
        onChangePage(cPage, paginationTotalRows || 0);
    };

    return (
        <Container>
            <DT
                key={key}
                columns={columns}
                data={data}
                pagination
                paginationComponent={(propsPagin) => (
                    <CustomPagination
                        {...propsPagin}
                        rowsPerPage={perPageLimit}
                        rowCount={paginationTotalRows || 0}
                        onChangePage={onHandlehangePage}
                        currentPage={page}
                    />
                )}
                paginationPerPage={perPageLimit}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onRowClicked={onRowClicked}
                paginationTotalRows={paginationTotalRows}
                noDataComponent={<CustomNoDataComponent />}
            />
        </Container>
    );
};

export default DataTable;
