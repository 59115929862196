import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CaretDownFill } from 'react-bootstrap-icons';
import styled from 'styled-components';

import { Colors } from 'src/utils/color';

const Container = styled.div<{
    width: string | undefined;
    marginLeft: number | undefined;
}>`
    position: relative;
    width: ${(props) => (props.width ? props.width : undefined)};
    margin-left: ${(props) =>
        props.marginLeft ? props.marginLeft + 'px' : '0px'};
`;

const DropdownButton = styled.div`
    background-color: ${Colors.dropdown.dropdown_bg};
    gap: 18px;
    border-radius: 100px;
    color: ${Colors.theme.primary};
    font-weight: 700;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
`;

const Menu = styled.div<{ isOpen: boolean; width?: number }>`
    position: absolute;
    margin-top: 5px;
    background-color: ${Colors.dropdown.dropdown_bg};
    border-radius: 4px;
    align-items: flex-start;
    padding: 20px 15px;
    gap: 10px;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    min-width: ${(props) => props.width}px;
`;

const Item = styled.div<{ active: boolean }>`
    width: fit-content;
    background-color: ${Colors.dropdown.menu_item_bg};
    color: ${(props) =>
        props.active
            ? Colors.theme.primary
            : props.color || Colors.extra.white};
    margin: 0;
    border-radius: 100px;
    cursor: pointer;
    padding: 10px 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
`;

const ArrowView = styled.div`
    margin-left: 30px;
`;

type DropdownOption = {
    value: number;
    label: string;
};

interface CustomDropdownProps {
    options: DropdownOption[];
    color?: string;
    selectedValue?: number;
    handleSelect: (selectedValue: number) => void;
    width?: string;
    marginLeft?: number;
    dropdownWidth?: number;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
    options,
    color,
    selectedValue,
    handleSelect = () => {},
    width,
    marginLeft,
    dropdownWidth
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectItem = (newSelectedValue: number) => {
        handleSelect(newSelectedValue);
        setIsOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectedLabel = useMemo(() => {
        const selectedOption = options.find((o) => o.value === selectedValue);

        if (selectedOption) {
            return selectedOption.label;
        }

        return undefined;
    }, [options, selectedValue]);

    return (
        <Container
            className="custom-dropdown"
            ref={dropdownRef}
            width={width}
            marginLeft={marginLeft}
        >
            <DropdownButton onClick={toggleDropdown}>
                <div>{selectedLabel}</div>
                <ArrowView className="dropdown-arrow">
                    <CaretDownFill color="black" size={10} />
                </ArrowView>
            </DropdownButton>
            <Menu
                className="dropdown-menu"
                isOpen={isOpen}
                width={dropdownWidth}
            >
                {options?.map((option) => (
                    <Item
                        color={color}
                        key={option.value}
                        className="dropdown-item"
                        active={selectedValue === option.value}
                        onClick={() => selectItem(option.value)}
                    >
                        {option.label}
                    </Item>
                ))}
            </Menu>
        </Container>
    );
};

export default CustomDropdown;
