import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Card, ListItems, Text } from 'src/shared';
import { SubScanSummary } from 'src/types/scan';
import { Colors } from 'src/utils/color';

const StyledBox = styled.div`
    padding: 20px 35px 45px 35px;
`;

const Container = styled.div`
    margin-right: 0;
    height: 100%;
`;

type Props = {
    summaryData: SubScanSummary | null;
};

const StatisticsCard: FunctionComponent<Props> = ({ summaryData }: Props) => {
    return (
        <Container>
            <Card>
                <StyledBox>
                    <Text
                        textAlign="left"
                        color={Colors.theme.primary}
                        fontWeight="600"
                        fontSize={18}
                    >
                        Statistics
                    </Text>
                    <Text
                        textAlign="left"
                        color={Colors.theme.primary}
                        fontWeight="400"
                        fontSize={14}
                    >
                        Count breakdown
                    </Text>

                    <ListItems
                        key="stats-1"
                        padding={3}
                        leftContent="# of retinal scans"
                        rightContent={
                            summaryData?.stats.saccade_count.toString() || 'N/A'
                        }
                    />
                    <ListItems
                        key="stats-2"
                        padding={3}
                        leftContent="# of 10-s videos"
                        rightContent="N/A"
                    />
                    <ListItems
                        key="stats-3"
                        padding={3}
                        leftContent="# of retina montage images"
                        rightContent="N/A"
                    />
                    <ListItems
                        key="stats-4"
                        padding={3}
                        leftContent="# of motion traces"
                        rightContent="N/A"
                    />
                    <ListItems
                        key="stats-5"
                        padding={3}
                        leftContent="# of saccadic motions"
                        rightContent="N/A"
                    />
                </StyledBox>
            </Card>
        </Container>
    );
};

export default StatisticsCard;
