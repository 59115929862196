import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import {
    ForgotPassword,
    Login,
    Patient,
    Report,
    ResetPassword,
    Scan
} from './pages';
import store from './store';

const App: FunctionComponent = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route
                        path="/patient/:patientId/scan/:scanId/report"
                        element={<Report />}
                    />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/patients" element={<Patient />} />
                    <Route path="/patient/:patientId/scan" element={<Scan />} />
                    <Route path="/rawdata" element={<Scan />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                </Routes>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
