import { FunctionComponent, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Colors } from 'src/utils/color';

const MainContainer = styled.div<{ width: string }>`
    width: ${(props) => props.width || '100%'};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 12px 16px;
    background-color: ${Colors.extra.white};
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 15px;
    position: relative;
`;

const InputView = styled.input`
    width: 98%;
    height: 40px;
    font-size: 18px;
    color: ${Colors.extra.black};
    border: none;
    border-bottom: 1px solid ${Colors.extra.light_gray};
    placeholdercolor: ${Colors.extra.light_gray};
    outline: none;
    background-color: transparent;
`;

const OutlinedInputView = styled.input`
    height: 56px;
    border: 1px solid ${Colors.extra.light_gray};
    border-radius: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 0px 12px;
`;

const Label = styled.div`
    width: 100%;
    color: ${Colors.extra.light_gray};
    font-size: 12px;
    font-weight: 600;
`;

const IconWrapper = styled.div`
    width: 5%;
    margin-bottom: 8px;
`;

const animation = keyframes`
    from {
        transform: 'translate(14px, 16px)';
        scale: 1;
    }
    to {
        transform: translate(14px, -10px);
        scale: 0.75;
    }
`;

const animation2 = keyframes`
    from {
        transform: 'translate(14px, -10px)';
        scale: 0.75;
    }
    to {
        transform: translate(14px, 16px);
        scale: 1;
    }
`;

const animationRule = css(
    ['', ' 0.4s forwards;'] as any as TemplateStringsArray,
    animation
);

const animationRule2 = css(
    ['', ' 0.4s forwards;'] as any as TemplateStringsArray,
    animation2,
    []
);

const OutlinedLabel = styled('label')<{
    animeStart: boolean;
    startReverse: boolean;
    startAnime: boolean;
}>`
    animation: ${(props) =>
        props.animeStart
            ? animationRule
            : props.startReverse
            ? animationRule2
            : null};
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: top left;
    transform: ${(props) =>
        !props.startAnime ? 'translate(14px, 16px)' : 'translate(14px, -10px)'};
    scale: ${(props) => (!props.startAnime ? '1' : '0.75')};
    pointer-events: none;
    z-index: 1;
    background-color: ${Colors.extra.white};
    padding: 0px 4px;
`;

interface Props {
    variant?: 'default' | 'outlined';
    value?: string;
    label: string;
    placeholder?: string;
    type?: string;
    icon?: any;
    width?: string;
    className?: string;
    onChange: (e: any) => void;
    name?: string;
}
const Input: FunctionComponent<Props> = ({
    placeholder,
    width,
    icon: Icon,
    type,
    onChange,
    label,
    className,
    name,
    variant = 'default'
}: Props) => {
    const [startAnimation, setStartAnimation] = useState<boolean>(false);
    const [startReverseAnimation, setStartReverseAnimation] =
        useState<boolean>(false);
    const [startAnime, setStartAnime] = useState<boolean>(false);
    const animeStatus = useRef<boolean>(false);

    return (
        <MainContainer width={width || '100%'} className={className}>
            {Icon && (
                <IconWrapper>
                    <Icon className="form-icon" />
                </IconWrapper>
            )}
            {variant === 'default' ? (
                <InputContainer>
                    <Label>{label}</Label>
                    <InputView
                        placeholder={placeholder}
                        type={type || 'text'}
                        onChange={onChange}
                        name={name}
                    />
                </InputContainer>
            ) : (
                <InputContainer>
                    <OutlinedLabel
                        startAnime={startAnime}
                        animeStart={startAnimation}
                        startReverse={startReverseAnimation}
                        onAnimationEnd={() => {
                            setStartAnime(animeStatus.current);
                        }}
                    >
                        {label}
                    </OutlinedLabel>
                    <OutlinedInputView
                        placeholder={placeholder}
                        type={type || 'text'}
                        onChange={onChange}
                        name={name}
                        onFocus={() => {
                            animeStatus.current = true;
                            setStartAnimation(true);
                        }}
                        onBlur={(e) => {
                            if (!e.target.value) {
                                animeStatus.current = false;
                                setStartAnimation(false);
                                setStartReverseAnimation(true);
                            }
                        }}
                    />
                </InputContainer>
            )}
        </MainContainer>
    );
};

export default Input;
