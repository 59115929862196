import moment from 'moment';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import {
    Navigate,
    useNavigate,
    useParams
} from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useAppDispatch } from 'src/hooks';
import { Loader } from 'src/pages/report/components';
import { ScanSelector, getScanList } from 'src/reducers/scan';
import {
    Card,
    DashboardWrapper,
    DataTable,
    SearchInput,
    Text
} from 'src/shared';
import { Size } from 'src/shared/text';
import { DataRow } from 'src/types/dataTable';
import { ScanData } from 'src/types/scan';
import { getAuthToken } from 'src/utils/auth';
import { Colors } from 'src/utils/color';

import {
    ColumnView,
    Container,
    ContentView,
    HeaderView,
    Wrapper
} from './styles';
import {
    retrieveUserProfile,
} from '../../services/sessionStorage';
import { UserProfile } from 'src/types/user';
import { isCLightUser } from 'src/utils/utils';

type Props = Record<string, never>;

const Scan: FunctionComponent<Props> = (_: Props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { patientId } = useParams();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [scans, setScans] = useState<ScanData[]>([]);
    const [searchInput, setSearchInput] = useState<string>('');

    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

    const defaultColumns: TableColumn<DataRow>[] = useMemo(() => {
        return [
            // {
            //     name: 'User',
            //     cell: (row: DataRow) => {
            //         return (
            //             <Text
            //                 fontSize={Size.XXXSmall}
            //                 lineHeight={'18px'}
            //                 fontWeight="400"
            //                 fontFamily="Inter"
            //             >
            //                 {row.user}
            //             </Text>
            //         );
            //     },
            //     width: '9%',
            //     center: true
            // },
            {
                name: 'Recorded at',
                cell: (row: DataRow) => (
                    <ColumnView>
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            fontWeight="400"
                            textAlign="center"
                            fontFamily="Inter"
                            marginBottom={0}
                        >
                            {moment(row.record_time).format('MMM DD, YYYY')}
                        </Text>
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            textAlign="center"
                            fontWeight="400"
                            fontFamily="Inter"
                            marginBottom={0}
                        >
                            {moment(row.record_time).format('LT')}
                        </Text>
                    </ColumnView>
                ),
                center: true
            },
            {
                name: 'Data file ID',
                cell: (row: DataRow) => {
                    return (
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            fontWeight="400"
                            fontFamily="Inter"
                        >
                            {row.hdf5_uuid}
                        </Text>
                    );
                },
                center: true
            },
            // {
            //     name: 'Video',
            //     cell: (row: DataRow) => {
            //         if (row.video) {
            //             const videos = String(row.video).split('\\n');
            //             return (
            //                 <UlContainer>
            //                     {videos.map((video: string, index: number) => (
            //                         <li key={index}>
            //                             <Text
            //                                 fontSize={Size.XXXSmall}
            //                                 lineHeight={'18px'}
            //                                 fontWeight="400"
            //                                 fontFamily="Inter"
            //                                 marginBottom={0}
            //                                 marginTop={10}
            //                             >
            //                                 {video}
            //                             </Text>
            //                         </li>
            //                     ))}
            //                 </UlContainer>
            //             );
            //         } else {
            //             return <></>;
            //         }
            //     },
            //     width: '25%',
            //     center: true
            // },
            {
                name: 'Serial number',
                cell: (row: DataRow) => {
                    return (
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            fontWeight="400"
                            fontFamily="Inter"
                        >
                            {row.serial_number}
                        </Text>
                    );
                },
                center: true
            },
            {
                name: 'Software version',
                cell: (row: DataRow) => {
                    return (
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            fontWeight="400"
                            fontFamily="Inter"
                        >
                            {row.app_software_version}
                        </Text>
                    );
                },
                center: true
            },
            {
                name: 'Tags',
                cell: (row: DataRow) => {
                    return (
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            fontWeight="400"
                            fontFamily="Inter"
                        >
                            {row?.tags}
                        </Text>
                    );
                },
                center: true
            },
            {
                name: 'Notes',
                cell: (row: DataRow) => {
                    return (
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            fontWeight="400"
                            fontFamily="Inter"
                        >
                            {row.notes}
                        </Text>
                    );
                },
                center: true
            }
        ];
    }, []); // No dependencies
    
    const [scanColumn, setScanColumn] = useState<any[]>(defaultColumns);
    useEffect(() => {
        // Set to false to display the group (org) name only for CLight (group_id=4). Set to true to always display.
        const isAlwaysDisplayGroup = false
        if (isCLightUser(userProfile) || isAlwaysDisplayGroup) {
            setScanColumn((prevColumns) => [
                ...defaultColumns,
                {
                    name: 'Group',
                    cell: (row: DataRow) => (
                        <Text
                            fontSize={Size.XXXSmall}
                            lineHeight={'18px'}
                            fontWeight="400"
                            fontFamily="Inter"
                        >
                            {row.group}
                        </Text>
                    ),
                    center: true
                }
            ]);
        }
    }, [userProfile, defaultColumns]);


    useEffect(() => {
        const profile = retrieveUserProfile();
        setUserProfile(profile);
    }, []);

    const debouncedSearch = useDebouncedCallback((newSearchValue: string) => {
        setSearchInput(newSearchValue);
        setCurrentPage(1);
        setScans([]);
    }, 1000);

    const { loading } = ScanSelector();

    useEffect(() => {
        if (patientId) {
            dispatch(
                getScanList({
                    page: currentPage,
                    limit,
                    patientId,
                    search: searchInput || undefined
                })
            ).then((scanData) => {
                setTotalCount(scanData.payload.data.total_count);
                setScans((currentScans) =>
                    currentScans.concat(scanData.payload.data.scans)
                );
            });
        } else {
            const undefinedPatientId = 'undefined';
            dispatch(
                getScanList({
                    page: currentPage,
                    limit,
                    patientId: undefinedPatientId,
                    search: searchInput || undefined
                })
            ).then((scanData) => {
                setTotalCount(scanData.payload.data.total_count);
                setScans(scanData.payload.data.scans);
            });            
        }
    }, [dispatch, patientId, currentPage, limit, searchInput]);

    const onChangePage = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    const onChangeRowsPerPage = useCallback((currentRowsPerPage: number) => {
        setLimit(currentRowsPerPage);
        setCurrentPage(1);
        setScans([]);
    }, []);

    const onSubmitSearchInput = useCallback(
        (value: string) => {
            debouncedSearch(value);
        },
        [debouncedSearch]
    );

    const onChangeSearchInput = useCallback(
        (value: string) => {
            debouncedSearch(value);
        },
        [debouncedSearch]
    );

    if (!getAuthToken()) return <Navigate to="/" />;
    return (
        <Wrapper>
            <DashboardWrapper>
                {loading && <Loader />}
                <Container>
                    <HeaderView>
                        <ColumnView>
                            <Text
                                fontSize={Size.XSmall}
                                color={Colors.extra.black}
                                fontWeight="600"
                                fontFamily="Inter"
                            >
                                Scan list
                            </Text>
                        </ColumnView>
                        <SearchInput
                            value={searchInput}
                            onSubmit={onSubmitSearchInput}
                            onChange={onChangeSearchInput}
                        />
                    </HeaderView>
                    <ContentView>
                        <Card width="100%">
                            <DataTable
                                columns={scanColumn}
                                data={scans}
                                perPageLimit={limit}
                                onChangePage={onChangePage}
                                onChangeRowsPerPage={onChangeRowsPerPage}
                                paginationTotalRows={totalCount}
                                onRowClicked={(row) => {
                                    navigate(
                                        `/patient/${patientId}/scan/${row.hdf5_uuid}/report`
                                    );
                                }}
                            />
                        </Card>
                    </ContentView>
                </Container>
            </DashboardWrapper>
        </Wrapper>
    );
};

export default Scan;

