import { FunctionComponent, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import Fullscreen from 'src/assets/icons/fullscreen.svg';
import PlayIcon from 'src/assets/icons/palyIcon.svg';
import PauseIcon from 'src/assets/icons/pauseIcon.png';
import { Colors } from 'src/utils/color';

const MainContainer = styled.div<{
    backgroundColor?: string;
    width?: string;
    height?: string;
}>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.backgroundColor || Colors.extra.black};
    border-radius: 4px;
    position: relative;
`;

const VideoContainer = styled.video`
    width: 100%;
    height: 100%;
`;

const ImageContainer = styled.img`
    width: 100%;
    height: 100%;
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
    margin-left: 6px;
`;

const FullscreenIcon = styled.img`
    width: 22px;
    height: 17px;
    position: absolute;
    right: 18px;
    bottom: 18px;
    cursor: pointer;
`;

const Circle = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 100%;
    position: absolute;
    border: 7px solid ${Colors.theme.primary};
    padding: 34px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props {
    backgroundColor?: string;
    width?: string;
    height?: string;
    url: string | null;
}

const VideoPlayer: FunctionComponent<Props> = ({
    backgroundColor,
    width,
    height,
    url
}: Props) => {
    const [isPlay, setIsPlay] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const playPauseVideo = useCallback(() => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                setIsPlay(true);
                videoRef.current.play();
            } else {
                setIsPlay(false);
                videoRef.current.pause();
            }
        }
    }, [videoRef]);

    const urlType = url
        ? (url.split(/[#?]/)[0].split('.').pop() || '').trim()
        : null;
    const isGif = urlType?.toLowerCase().includes('gif');

    return (
        <MainContainer
            width={width}
            height={height}
            backgroundColor={backgroundColor}
        >
            {url &&
                (isGif ? (
                    <ImageContainer src={url} alt="preview" />
                ) : (
                    <VideoContainer ref={videoRef}>
                        <source src={url} type={'video/' + urlType} />
                    </VideoContainer>
                ))}

            {!isGif && (
                <Circle onClick={playPauseVideo}>
                    <Icon src={isPlay ? PauseIcon : PlayIcon} />
                </Circle>
            )}
            {!isGif && (
                <FullscreenIcon
                    src={Fullscreen}
                    onClick={() => videoRef.current?.requestFullscreen()}
                />
            )}
        </MainContainer>
    );
};

export default VideoPlayer;
