import React, {
    FunctionComponent,
    forwardRef,
    useImperativeHandle,
    useState
} from 'react';
import { Modal as RBModal } from 'react-bootstrap';

import Text, { Size } from '../text';
import { Colors } from 'src/utils/color';

type Props = {
    ref?: React.Ref<HTMLInputElement>;
    children?: React.ReactNode;
    title?: string;
    bgColor?: string;
};

const Modal: FunctionComponent<Props> = forwardRef<any, Props>((props, ref) => {
    const { children, title, bgColor } = props;
    const [show, setShow] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
        show: () => {
            setShow(true);
        },
        hide: () => {
            setShow(false);
        }
    }));

    return (
        <RBModal show={show} centered>
            {title && (
                <RBModal.Header
                    style={{
                        background: bgColor,
                        border: `1px solid ${bgColor}`,
                        borderRadius: '4px'
                    }}
                >
                    <RBModal.Title>
                        <Text
                            color={Colors.extra.black}
                            fontSize={Size.XSmall}
                            fontFamily="Inter"
                            fontWeight="600"
                        >
                            {title}
                        </Text>
                    </RBModal.Title>
                </RBModal.Header>
            )}
            <RBModal.Body
                style={{
                    background: bgColor
                }}
            >
                {children}
            </RBModal.Body>
        </RBModal>
    );
});

export default Modal;
