const PRIMARY = '#0056B3'; // Darkened for better contrast
const LIGHT_BLACK = '#363842';
const BLACK = '#000';
const WHITE = '#FFFFFF';
const GRAY = '#A3A6B4'; // Softened gray
const LIGHT_GRAY = '#D3D3D3'; // Lightened for subtle backgrounds
const DARK_GRAY = '#2F3136'; // Added for text and important elements
const RED = '#CC0000'; // Darkened for better visibility
const NAVY_BLUE = '#7FA4F1';
const ELEVATION_1 = '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)';
const BLUE_1 = '#4C9AFF'; // Brightened for visibility
const HALF_GRAY = '#D2D4DE';
const BLUE_2 = '#003366'; // Darkened for contrast
const BLUE_3 = '#0056B3'; // Darkened to match the primary color
const WHITE_90 = 'rgba(255, 255, 255, 0.9)';
const WHITE_1 = '#F8F8F8';
const GRAY_1 = '#F0F0F0'; // Lightened for a fresher look
const GRAY_2 = '#505050'; // Darkened for readability
const GRAY_3 = '#BEBEBE';
const GRAY_4 = '#5A5A5A';
const RADAR_BLUE_1 = '#165BAA';
const RADAR_BLUE_2 = '#63ABFD';
const RADAR_YELLOW = '#FABB18';
const MG_GRAY_1 = '#82848C';
const MG_BLUE_1 = '#4E77B2';
const MG_GRAY_2 = '#5C6171';
const MG_GRAY_3 = '#cfd1d5';
const MG_GREEN = '#95f92d';
const MG_DARK_BLUE = '#20273a';
const WHITE_30 = 'rgba(255, 255, 255, 0.3)';
const CPM_BLUE_GRADIENT = 'linear-gradient(105.23deg, #0063d7 0%, #0063d7 100%)';
const TABLE_HEADER = '#707070'; // Darkened for readability
const TABLE_ODD_ROW = '#E8E8E8'; // Lightened for contrast
const TABLE_EVEN_ROW = '#D0D0D0'; // Lightened for contrast
const LABELCOLOR = '#B7BEE2';
const PIE_CELL_ONE = '#3F7CC4';
const PIE_CELL_TWO = '#6D95F5';

export const Colors = {
    theme: {
        primary: PRIMARY,
        secondary: GRAY,
        page_background: GRAY_1
    },
    table: {
        header: TABLE_HEADER,
        oddrow: TABLE_ODD_ROW,
        evenrow: TABLE_EVEN_ROW
    },
    dropdown: {
        dropdown_bg: GRAY,
        menu_item_bg: LIGHT_GRAY,
        dropdown_border: PRIMARY,
        dropdown_text: PRIMARY
    },
    switch: {
        handle_shadow: ELEVATION_1,
        track_bg: BLUE_1
    },
    date_picker: {
        select_txt: BLUE_2,
        preset_txt: WHITE_90,
        preset_bg: LIGHT_GRAY,
        clear_bg: WHITE_1,
        clear_txt: GRAY_2,
        apply_bg: BLUE_3,
        calendar_border: GRAY_3,
        month_txt: GRAY_4
    },
    radar: {
        blue1: RADAR_BLUE_1,
        blue2: RADAR_BLUE_2,
        yellow: RADAR_YELLOW
    },
    motion_graph: {
        axis_labels: MG_GRAY_1,
        legend: MG_BLUE_1,
        axis: MG_GRAY_2,
        distort_green: MG_GREEN,
        gray_legend: MG_GRAY_3,
        dark_blue: MG_DARK_BLUE
    },
    change_password_modal: {
        save_button: CPM_BLUE_GRADIENT
    },
    extra: {
        white: WHITE,
        black: BLACK,
        light_black: LIGHT_BLACK,
        red: RED,
        navy_blue: NAVY_BLUE,
        label_bg: LABELCOLOR,
        cell_bg_one: PIE_CELL_ONE,
        cell_bg_two: PIE_CELL_TWO,
        modal_bg: HALF_GRAY,
        light_gray: LIGHT_GRAY,
        white30: WHITE_30,
        dark_gray: DARK_GRAY // Added for text and important elements
    }
};
