import styled from 'styled-components';

import { Colors } from 'src/utils/color';

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    width: 100%;
    background-color: ${Colors.theme.secondary};
`;

export const NavButton = styled.button`
    position: relative;
    display: block;
    user-select: none;
    border: none;
    ${({ theme }) => theme.pagination.pageButtonsStyle};
    bottom: 2px;
`;

export const DropdownWrapper = styled.div`
    margin-bottom: 1px;
    & .custom-dropdown > div:first-child {
        font: 400 12px/12px 'Inter', sans-serif;
        color: black;
    }
    & .dropdown-arrow {
        margin-left: -10px;
    }
`;
