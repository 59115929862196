import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Colors } from 'src/utils/color';

const StyledCard = styled.div<Props>`
    background-color: ${(props) =>
        props.backgroundColor || Colors.theme.secondary};
    border-radius: 4px;
    border: none;
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
`;

interface Props {
    children?: React.ReactNode;
    backgroundColor?: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    className?: string;
}

const Card: FunctionComponent<Props> = ({ children, ...props }: Props) => {
    return <StyledCard {...props}>{children}</StyledCard>;
};

export default Card;
