import { useEffect, useRef, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { daysInMonth, months, presets } from './helpers';
import {
    Actions,
    Apply,
    Button,
    ButtonText,
    Calendar,
    Clear,
    Container,
    Custom,
    Modal,
    Month,
    Popper,
    Preset,
    Presets,
    Range,
    Selector,
    StyledArrowDown,
    StyledCalendar,
    StyledClockIcon,
    StyledRDP
} from './styled';

interface Props {
    onSelect: (selectedRange: string, startDate: Date, endDate: Date) => void;
}

const DatePicker = (props: Props) => {
    const { onSelect } = props;
    const ctn: React.RefObject<any> = useRef();

    const [selectedMonth, setSelectedMonth] = useState<number>(
        new Date().getMonth()
    );
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedRange, setSelectedRange] = useState<string>('');
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);
    const [datesPadding, setDatesPadding] = useState<number>(8);
    const [buttonText, setButtonText] = useState<string>('Select date');

    const handlerOutCalendar = (event: any) => {
        if (
            ctn.current &&
            !ctn.current.contains(event.target) &&
            !Number(event.target.innerText)
        ) {
            setModalVisibilityHandler(false);
        }
    };

    const setModalVisibilityHandler = (visibility: boolean) => {
        setModalVisibility(visibility);
        if (visibility) {
            document.addEventListener('click', handlerOutCalendar);
        } else {
            document.removeEventListener('click', handlerOutCalendar);
        }
    };

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setSelectedRange('Custom range');
        setSelectedMonth(start.getMonth());
        adjustCalendarHeight(start.getMonth());
    };

    const onClickPreset = (preset: string) => {
        let _startDate = new Date();
        let _endDate = new Date();
        switch (preset) {
            case 'Last day':
                setSelectedMonth(_startDate.getMonth());
                _startDate.setDate(_startDate.getDate() - 1);
                _endDate.setDate(_endDate.getDate() - 1);
                break;
            case 'Last 8 days':
                setSelectedMonth(_startDate.getMonth());
                _startDate.setDate(_startDate.getDate() - 8);
                _endDate.setDate(_endDate.getDate() - 1);
                break;
            case 'Last month':
                setSelectedMonth(_startDate.getMonth() - 1);
                _startDate.setMonth(_startDate.getMonth() - 1);
                _startDate.setDate(1);
                _endDate.setDate(0);
                break;
            case 'Last quarter':
                setSelectedMonth(_startDate.getMonth() - 4);
                _startDate.setMonth(_startDate.getMonth() - 4);
                _startDate.setDate(1);
                _endDate.setDate(0);
                break;
            case 'Last year':
                _startDate.setFullYear(_startDate.getFullYear() - 1);
                _startDate.setMonth(0);
                _startDate.setDate(1);
                _endDate.setMonth(0);
                _endDate.setDate(0);
                break;
        }
        setSelectedRange(preset);
        setStartDate(_startDate);
        setEndDate(_endDate);
        adjustCalendarHeight(_startDate.getMonth());
    };

    const onClickClear = () => {
        setSelectedMonth(new Date().getMonth());
        setStartDate(new Date());
        setEndDate(new Date());
        setButtonText('Select date');
        setModalVisibilityHandler(false);
    };

    const onClickApply = () => {
        setButtonText(selectedRange || 'Select date');
        onSelect(selectedRange, startDate, endDate);
        setModalVisibilityHandler(false);
    };

    const onSelectMonth = (index: number) => {
        setSelectedMonth(index);
        const now = new Date();
        now.setMonth(index);
        setStartDate(now);
        setEndDate(now);
        setSelectedRange('');
        adjustCalendarHeight(index);
    };

    const adjustCalendarHeight = (month: number) => {
        const selected = new Date();
        selected.setMonth(month);
        selected.setDate(1);
        if (
            (selected.getDay() === 5 && daysInMonth[month] === 31) ||
            (selected.getDay() === 6 && daysInMonth[month] >= 30)
        ) {
            setDatesPadding(6);
        } else {
            setDatesPadding(8);
        }
    };

    useEffect(() => {
        if (modalVisibility) {
            document
                .querySelector(`#month-slider-${selectedMonth}`)
                ?.scrollIntoView({ block: 'center' });
        }
    }, [selectedMonth, modalVisibility]);

    return (
        <Container ref={ctn}>
            <Button onClick={() => setModalVisibilityHandler(!modalVisibility)}>
                <ButtonText>{buttonText}</ButtonText>
                <StyledArrowDown />
            </Button>
            <Modal visible={modalVisibility}>
                <Presets>
                    <StyledClockIcon />
                    {presets.map((preset, index) => (
                        <Preset
                            key={index}
                            onClick={() => onClickPreset(preset)}
                            selected={selectedRange === preset}
                        >
                            {preset}
                        </Preset>
                    ))}
                </Presets>
                <Custom>
                    <Selector>
                        <Calendar datesPadding={datesPadding}>
                            <StyledRDP
                                key={selectedMonth}
                                selectsRange
                                selected={startDate}
                                onChange={onChange}
                                startDate={startDate}
                                endDate={endDate}
                                open={true}
                                customInput={<></>}
                                renderCustomHeader={() => <></>}
                                showPopperArrow={false}
                                popperContainer={Popper}
                                calendarContainer={StyledCalendar}
                            />
                        </Calendar>
                        <Range>
                            {months.map((month, index) => (
                                <Month
                                    id={`month-slider-${index}`}
                                    key={index}
                                    selected={index === selectedMonth}
                                    onClick={() => onSelectMonth(index)}
                                >
                                    {month}
                                </Month>
                            ))}
                        </Range>
                    </Selector>
                    <Actions>
                        <Clear onClick={onClickClear}>Clear</Clear>
                        <Apply onClick={onClickApply}>Apply</Apply>
                    </Actions>
                </Custom>
            </Modal>
        </Container>
    );
};

export default DatePicker;
