import { FunctionComponent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Colors } from 'src/utils/color';

import DropContainer from './dropContainer';

type Props = {
    onSelect: (fileName: string) => void;
};
const UploadContainer: FunctionComponent<Props> = ({ onSelect }: Props) => {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            // Do something with the files
            if (acceptedFiles[0].size / 1024 > 20000) {
                alert('file size limit exceeded!');
            } else {
                const fileName = acceptedFiles[0].name;
                onSelect(fileName);
            }
        },
        [onSelect]
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
                <DropContainer color={Colors.dropdown.menu_item_bg} />
            ) : (
                <DropContainer color={Colors.dropdown.dropdown_bg} />
            )}
        </div>
    );
};

export default UploadContainer;
