import styled from 'styled-components';

import { Colors } from 'src/utils/color';

export const InputContainer = styled.div`
    border: 1px solid ${Colors.extra.black};
    height: 60px;
    position: relative;
    border-radius: 4px;
`;

export const InputLabelWrapper = styled.span`
    position: absolute;
    top: -7px;
    left: 10px;
    background-color: ${Colors.extra.modal_bg};
    padding: 0 5px;
`;

export const LoaderlWrapper = styled.span`
    width: 126px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledInput = styled.input`
    &:focus {
        border: 1px solid ${Colors.extra.black} !important;
    }
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
`;

export const fontFamily = '"Inter",sans-serif';

export const Gap = styled.div<{ y: number }>`
    height: ${(p) => p.y}px;
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    & .save-button {
        background: ${Colors.change_password_modal.save_button};
        margin-left: 8px;
        line-height: 17px;
        letter-spacing: -0.04em;
    }
    & .cancel-button {
        line-height: 17px;
        letter-spacing: -0.04em;
    }
`;
