import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'src/hooks';
import { resetPassword, verifyToken } from 'src/reducers/user';
import { Modal as SharedModal, Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { ResetPasswordData } from 'src/types/resetPassword';
import { getAuthToken } from 'src/utils/auth';
import { Colors } from 'src/utils/color';

import {
    CancelButton,
    Input,
    PasswordSetText,
    ResetPasswordText,
    SaveButton,
    ThankyouText
} from './components';
import { intitalValues, passwordTypes, validationSchema } from './constants';
import { ActionsContainer, Container, Gap, StyledModalBody } from './styled';

const ResetPassword: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useSearchParams();
    const tokenExpireModalRef = useRef<any>(null);
    const [error, setError] = useState('');

    const onCancel = useCallback(() => {
        navigate('/');
    }, [navigate]);

    const verifyErrorHandle = useCallback(() => {
        tokenExpireModalRef?.current?.show();
        setTimeout(() => {
            tokenExpireModalRef?.current?.hide();
            navigate('/forgot-password');
        }, 5000);
    }, [navigate]);

    const verifyTokenApi = useCallback(
        (code: string) => {
            dispatch(
                verifyToken({
                    token: code
                })
            )
                .unwrap()
                .then((response: any) => {
                    if (!response?.success) {
                        verifyErrorHandle();
                    }
                })
                .catch(() => {
                    verifyErrorHandle();
                });
        },
        [dispatch, verifyErrorHandle]
    );

    useEffect(() => {
        const code = params[0].get('code');
        if (!code) {
            onCancel();
        } else {
            verifyTokenApi(code);
        }
    }, [params, onCancel, verifyTokenApi]);

    const onSubmit = useCallback(
        async (
            data: ResetPasswordData,
            { setSubmitting }: FormikHelpers<ResetPasswordData>
        ) => {
            setError('');
            dispatch(
                resetPassword({
                    token: params[0].get('code') + '',
                    password: data['New Password']
                })
            )
                .unwrap()
                .then((response: any) => {
                    if (response?.success) {
                        setSuccessMessage(true);
                        setTimeout(() => {
                            setSuccessMessage(false);
                            onCancel();
                        }, 2000);
                    } else {
                        setSubmitting(false);
                        setError(response?.message);
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    setError('Something went wrong. Please try again.');
                });
        },
        [dispatch, params, onCancel]
    );

    const [successMessage, setSuccessMessage] = useState(false);

    if (getAuthToken()) return <Navigate to="/dashboard" />;
    return (
        <Container>
            <ResetPasswordText />
            <Gap y={28} />
            <Formik
                initialValues={intitalValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    errors,
                    touched
                }: any) => (
                    <>
                        <Form>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {passwordTypes.map((pt, index) => (
                                <React.Fragment key={index}>
                                    <Input
                                        label={pt}
                                        value={values[pt]}
                                        onChange={handleChange(pt)}
                                        touched={touched[pt]}
                                        error={errors[pt]}
                                    />
                                </React.Fragment>
                            ))}
                            <Gap y={30} />
                        </Form>
                        <ActionsContainer>
                            <CancelButton onClick={onCancel} />
                            <SaveButton onClick={handleSubmit} />
                        </ActionsContainer>
                    </>
                )}
            </Formik>
            <Modal show={successMessage} centered size="sm">
                <StyledModalBody>
                    <PasswordSetText />
                    <ThankyouText />
                </StyledModalBody>
            </Modal>
            <SharedModal
                ref={tokenExpireModalRef}
                bgColor={Colors.theme.page_background}
            >
                <StyledModalBody>
                    <Text
                        color={Colors.extra.black}
                        fontFamily="Inter"
                        fontWeight="700"
                        fontSize={Size.XSmall}
                        marginTop={0}
                    >
                        Your token was expired
                    </Text>
                    <CancelButton
                        onClick={() => navigate('/forgot-password')}
                    />
                </StyledModalBody>
            </SharedModal>
        </Container>
    );
};

export default ResetPassword;
