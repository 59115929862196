import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0;
`;

export const Container = styled.div`
    margin-left: 2%;
    margin-right: 2%;
`;

export const HeaderView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 30px;
    margin-left: 25px;
    margin-right: 15px;
    align-items: center;
`;

export const RowView = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ColumnView = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UlContainer = styled.ul`
    margin-bottom: 10px;
`;

export const ContentView = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    margin-bottom: 13px;
    gap: 9px;
`;
