import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import { Colors } from 'src/utils/color';

export const Container = styled(Row)`
    width: 100%;
    height: auto;
    display: flex;
    border-radius: 4px;
    flex-direction: row;
    background-color: ${Colors.theme.secondary};
    padding: 10px 0px;
    @media (max-width: 1199px) {
        margin-top: 16px;
        margin-left: 0px;
    }
    @media (max-width: 991.99px) {
        .col {
            border-bottom: 5px solid rgb(217, 217, 217);
        }
    }
`;

export const CircularProgressContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 10px;
    padding-top: 16px;
    @media (min-width: 992px) {
        border-right: 1px solid ${Colors.extra.white};
    }
`;

export const RadarContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 16px;
    margin-left: 10px;
    @media (min-width: 992px) {
        border-right: 1px solid ${Colors.extra.white};
    }
`;

export const LastContainer = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 16px;
    margin-left: 10px;
`;

export const DividerLine = styled.div`
    width: 95%;
    border: 1.5px solid ${Colors.extra.white};
    margin-top: 14px;
    margin-bottom: 18px;
`;
