import * as yup from 'yup';

import { ResetPasswordData } from 'src/types/resetPassword';

export const passwordTypes = ['New Password', 'Confirm Password'];

export const intitalValues: ResetPasswordData = {
    'New Password': '',
    'Confirm Password': ''
};

export const validationSchema = yup.object().shape({
    'New Password': yup.string().required('New password is required'),
    'Confirm Password': yup
        .string()
        .required('Confirm password is required')
        .oneOf([yup.ref('New Password')], 'Passwords must match')
});
