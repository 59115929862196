import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

import { Colors } from 'src/utils/color';

export const Root = styled.div<{ widthPercentage: number }>`
    display: inline-block;
    position: relative;
    width: ${(p) => p.widthPercentage}%;
`;

export const ChartContainer = styled.div<{ width: string; height: number }>`
    width: ${(p) => p.width};
    height: ${(p) => p.height}px;
    background-color: ${Colors.motion_graph.dark_blue};
`;

export const StyledResponsiveContainer = styled(ResponsiveContainer)`
    background-color: ${Colors.motion_graph.dark_blue};
`;

export const LegendContainer = styled.div<{ width: number; height: number }>`
    background-color: ${Colors.motion_graph.gray_legend};
    width: 150px;
    height: 30px;
    position: absolute;
    left: ${(p) => p.width}px;
    top: ${(p) => p.height - 295}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 5px;
    border-radius: 3px;
    z-index: 2;
`;

export const Legend = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
