import { AUTHORIZATION_HEADER_NAME, getAuthToken } from 'src/utils/auth';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || '/';

let controller: AbortController;
let signal: AbortSignal;

function _resetAbortController() {
    controller = new AbortController();
    signal = controller.signal;
}

// init controller and signal
_resetAbortController();

export function abortPreviousRequests(): void {
    controller.abort();

    // replace controller and signal so we have a new unaborted one (otherwise the
    // controller remains aborted and will abort any fetch using its signal immediately)
    _resetAbortController();
}

export function login(email: string, password: string): Promise<any> {
    return _callAPI('user/login', 'POST', { email, password });
}

export function changePassword(
    email: string,
    old_password: string,
    new_password: string
): Promise<any> {
    return _callAuthenticatedAPI('user/login/change-password', 'POST', {
        email,
        old_password,
        new_password
    });
}

export function verifyToken(token: string): Promise<any> {
    return _callAuthenticatedAPI('user/reset/verify', 'POST', {
        token
    });
}

export function resetPassword(token: string, password: string): Promise<any> {
    return _callAuthenticatedAPI('user/reset/confirm', 'POST', {
        token,
        password
    });
}

export function forgetPassword(email: string): Promise<any> {
    return _callAuthenticatedAPI('user/reset/request', 'POST', {
        email
    });
}

export function getUser(
    type: string,
    page: number,
    limit: number,
    search?: string
): Promise<any> {
    return _callAuthenticatedAPI(
        `user?type=${type}&page=${page}&limit=${limit}` +
            (search ? `&search=${search}` : '')
    );
}

export function getPatients(
    page: number,
    limit: number,
    search?: string
): Promise<any> {
    return _callAuthenticatedAPI(
        `scan/patients?page=${page}&results_per_page=${limit}` +
            (search ? `&search=${search}` : '')
    );
}

export function getPatientScans(
    patientId: string,
    page: number,
    limit: number,
    search?: string
): Promise<any> {
    // return _callAuthenticatedAPI(
    //     `scan/patient/${patientId}/scans?page=${page}&limit=${limit}` +
    //         (search ? `&search=${search}` : '')
    // );
    return getRawDataScans(page, limit, search)
}

export function getRawDataScans(
    page: number,
    limit: number,
    search?: string
): Promise<any> {
    // return _callAuthenticatedAPI(
    //     `scan/patient/${patientId}/scans?page=${page}&limit=${limit}` +
    //         (search ? `&search=${search}` : '')
    // );
    return _callAuthenticatedAPI(
        `scan/rawdata?page=${page}&limit=${limit}`+
                (search ? `&search=${search}` : '')
    );
}


export function getSubScans(patientId: string, scanId: string): Promise<any> {
    return _callAuthenticatedAPI(
        `scan/${scanId}/sub-scans`
    );
}

export function getSubScanPreview(
    patientId: string,
    scanId: string,
    mode: number,
    odos: number,
    scan: number
): Promise<any> {
    return _callAuthenticatedAPI(
        `scan/${scanId}/sub-scan/${mode}/${odos}/${scan}/preview`
    );
}

export function getSubScanTrace(
    patientId: string,
    scanId: string,
    mode: number,
    odos: number,
    scan: number
): Promise<any> {
    return _callAuthenticatedAPI(
        `scan/${scanId}/sub-scan/${mode}/${odos}/${scan}/trace`
    );
}

export function getSubScanSummary(
    patientId: string,
    scanId: string,
    mode: number,
    odos: number,
    scan: number
): Promise<any> {
    return _callAuthenticatedAPI(
        `scan/${scanId}/sub-scan/${mode}/${odos}/${scan}/summary`
    );
}

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'HEAD';

function _callAuthenticatedAPI(
    endpoint: string,
    method: MethodType = 'GET',
    body?: { [key: string]: any },
    abortable: boolean = false
): Promise<any> {
    const headers = {
        [AUTHORIZATION_HEADER_NAME]: `Token ${getAuthToken()}`
    };

    return _callAPI(endpoint, method, body, headers, abortable);
}

function _callAPI(
    endpoint: string,
    method: MethodType = 'GET',
    body?: { [key: string]: any },
    headers?: { [key: string]: string },
    abortable: boolean = false
): Promise<any> {
    const config: {
        method: string;
        headers: { [key: string]: string };
        body?: string;
        signal?: AbortSignal;
    } = {
        method,
        headers: headers || {}
    };

    if (body) {
        config['headers']['Content-Type'] = 'application/json';
        config['body'] = JSON.stringify(body);
    }

    if (abortable) {
        config['signal'] = signal;
    }

    return fetch(BASE_URL + endpoint, config)
        .catch((err) => {
            // reject with unknown error, but aborting an abortable request should be
            // distinguishable as it's not necessarily an erroneous path
            return Promise.reject({
                status: -1,
                aborted: abortable && err.name === 'AbortError'
            });
        })
        .then((response) =>
            response
                .json()
                .catch((_err) => {
                    return Promise.reject({ status: response.status });
                })
                .then((responseBody) => {
                    if (response.ok) {
                        return Promise.resolve(responseBody);
                    } else {
                        // error and description may not be available
                        return Promise.reject({
                            status: response.status,
                            data: responseBody,
                            error: responseBody.error,
                            description: responseBody.description
                        });
                    }
                })
        );
}
