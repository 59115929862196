import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0;
`;

export const HeaderView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-left: 25px;
    margin-right: 15px;
`;

export const RowView = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ContentView = styled(Row)`
    padding-left: 20px;
    padding-right: 32px;
    margin-bottom: 13px;
    .player-view {
        margin-top: 16px;
        @media (max-width: 1199px) {
            margin-top: 24px;
        }
    }
`;

export const LabelContainer = styled.div<{ flexDirection: string }>`
    display: flex;
    flex-direction: ${(p) => p.flexDirection};
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    width: 100%;
`;

export const Label = styled.div`
    text-align: center;
    flex: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    padding-bottom: 20px;
`;
