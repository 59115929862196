import { FunctionComponent } from 'react';

import { ReactComponent as AvatarDropDown } from 'src/assets/icons/avatarDropDown.svg';
import { ReactComponent as GifIcon } from 'src/assets/icons/gifIcon.svg';
import { ReactComponent as VideoIcon } from 'src/assets/icons/video.svg';
import { Text } from 'src/shared';
import { Colors } from 'src/utils/color';

import {
    ImagesWrapper,
    TextClick,
    TextFileSize,
    TextWrapper,
    Wrapper
} from './styled';

type Props = {
    color: string;
};

const DropContainer: FunctionComponent<Props> = ({ color }: Props) => {
    return (
        <Wrapper color={color}>
            <AvatarDropDown />
            <TextWrapper>
                <TextClick
                    color={Colors.dropdown.dropdown_text}
                    padding={5}
                    textDecorationLine="underline"
                    marginBottom={0}
                >
                    Click to upload
                </TextClick>
                <Text marginBottom={0}>or drag and drop</Text>
            </TextWrapper>
            <TextFileSize>GIF or MOV (max. 20MB)</TextFileSize>
            <ImagesWrapper>
                <GifIcon />
                <VideoIcon />
            </ImagesWrapper>
        </Wrapper>
    );
};

export default DropContainer;
