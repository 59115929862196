import styled from 'styled-components';

import SharedText from '../text';
import { Colors } from 'src/utils/color';

export const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 65px);
    display: flex;
`;

export const LeftBarContainer = styled.div`
    width: 64px;
    background: ${Colors.theme.page_background};
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: solid;
    border-color: ${Colors.extra.white};
`;

export const Content = styled.div`
    flex: 1;
    padding-top: 12px;
    background-color: ${Colors.theme.page_background};
    overflow-y: scroll;
    width: 97vw;
`;

export const IconButton = styled.a`
    width: 64px;
    height: 64px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Topbar = styled.div`
    width: 100%;
    height: 65px;
    background: ${Colors.theme.secondary};
    display: flex;
    padding-top: 9px;
    padding-bottom: 9px;
    align-items: center;
`;

export const Icons = styled.img`
    width: 217px;
    height: 47px;
    margin-left: 8px;
    margin-right: 40px;
`;

export const Text = styled(SharedText)`
    margin-right: 16px;
    cursor: pointer;
    font-size: 13px;
`;

export const RightNavWrapper = styled.div`
    height: 100%;
    margin-left: auto;
    display: flex;
    align-items: center;
`;

export const NavIconWrapper = styled.div`
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlayIconWrapper = styled.div`
    height: 25px;
    width: 23px;
    border: solid;
    border-color: ${Colors.extra.black};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const IconStyle = {
    marginRight: '16px'
};

export const Menu = styled.div<{ isOpen: boolean; width?: number }>`
    position: absolute;
    right: 10px;
    top: 50px;
    background-color: ${Colors.dropdown.dropdown_bg};
    border-radius: 4px;
    align-items: flex-start;
    padding: 13px 15px;
    gap: 10px;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    min-width: ${(props) => props.width}px;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const SettingMenu = styled.div<{ isOpen: boolean; width?: number }>`
    left: 65px;
    position: absolute;
    top: 270px;
    background-color: ${Colors.dropdown.dropdown_bg};
    border-radius: 4px;
    align-items: flex-start;
    padding: 13px 15px;
    gap: 10px;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    min-width: ${(props) => props.width}px;
    z-index: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const Item = styled.div`
    height: 29px;
    background-color: ${Colors.dropdown.menu_item_bg};
    border-radius: 100px;
    cursor: pointer;
`;

export const EmptyView = styled.div`
    display: flex;
    flex: 1;
    width: 64px;
`;
