import { saveAs } from 'file-saver';
import React from 'react';
import styled from 'styled-components';

import DownloadIcon from 'src/assets/icons/download.png';
import GifIcon from 'src/assets/icons/gif.png';

const ImageGifContainer = styled.div`
    width: 160px !important;
    height: 230px !important;
    cursor: pointer;
`;

const Icons = styled.img`
    width: 24px;
    height: 27px;
    cursor: pointer;
    margin-right: 10px;
`;

const IconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 10px;
`;

const Image = styled.img<any>`
    width: 100%;
    height: 100%;
`;

const Thumbnails = styled.video`
    width: 100%;
    height: 100%;
    object-fit: fill;
`;

type MediaType = 'image' | 'gif' | 'video';

interface ImgGifViewerProps {
    src: string;
    type: MediaType;
}

const downloadFile = async (imageUrl: string) => {
    fetch(imageUrl, { mode: 'no-cors' })
        .then((response) => response.blob())
        .then((blob) => {
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            saveAs(blob, filename);
        })
        .catch(() => {
            alert('Failed to download');
        });
};

const extractYouTubeThumbnail = (src: string): string => {
    const videoIdMatch = src.match(
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^#\\&\\?]*).*/i
    );
    if (videoIdMatch && videoIdMatch[1]) {
        return `https://img.youtube.com/vi/${videoIdMatch[1]}/maxresdefault.jpg`;
    }
    return '';
};

const ImgGifViewer: React.FC<ImgGifViewerProps> = ({ src, type }) => {
    let thumbnailVideo = '';
    if (type === 'video') {
        thumbnailVideo = extractYouTubeThumbnail(src);
    }

    return (
        <ImageGifContainer>
            {type === 'image' && <Image src={src} alt="Image" />}
            {type === 'gif' && <Image src={src} />}
            {type === 'video' && <Thumbnails poster={thumbnailVideo} />}{' '}
            <IconsContainer>
                <Icons onClick={() => downloadFile(src)} src={DownloadIcon} />
                {type === 'gif' && <Icons src={GifIcon} />}
            </IconsContainer>
        </ImageGifContainer>
    );
};

export default ImgGifViewer;
