import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10%;
`;

export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 4%;
`;
