import { FunctionComponent } from 'react';
import { XCircle } from 'react-bootstrap-icons';
import styled from 'styled-components';

import CircleCheck from 'src/assets/icons/circleCheck.png';
import UploadIcons from 'src/assets/icons/uploadIcon.png';
import { IconButton, LinearProgressBar, Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { Colors } from 'src/utils/color';

const Icons = styled.div`
    display: flex;
    justify-content: space-between;
    width: fit-content;
    margin-left: 10px;
`;
const ProgressContainer = styled.div`
    width: 90%;
`;

const Item = styled.div`
    width: fit-content;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
`;

type Props = {
    title: string;
    totalUnit?: string;
    status?: string;
    progress: number;
    onRemove?: () => void;
};

const UploadItem: FunctionComponent<Props> = ({
    title,
    status,
    totalUnit,
    progress,
    onRemove
}: Props) => {
    return (
        <Row>
            <Item>
                <IconButton
                    icon={UploadIcons}
                    padding="0"
                    backgroundColor={Colors.extra.navy_blue}
                    width="40px"
                    height="40px"
                    marginRight="20px"
                />
            </Item>
            <ProgressContainer>
                <Text
                    color={Colors.extra.black}
                    fontSize={Size.XSmall}
                    fontWeight="400"
                    marginBottom={0}
                >
                    {title}
                </Text>
                <LinearProgressBar
                    progress={progress}
                    totalUnit={totalUnit}
                    status={status}
                />
            </ProgressContainer>
            <Icons>
                <IconButton
                    icon={CircleCheck}
                    backgroundColor={Colors.theme.page_background}
                    padding="0"
                    width="24px"
                    height="24px"
                />
                <XCircle
                    size={21}
                    color={Colors.extra.black}
                    onClick={onRemove}
                    style={styles.CircleStyle}
                />
            </Icons>
        </Row>
    );
};

export default UploadItem;

const styles: { [key: string]: React.CSSProperties } = {
    CircleStyle: {
        cursor: 'pointer',
        marginLeft: '30px'
    }
};
