import { FC } from 'react';
import { Cross } from 'recharts';

import { Colors } from 'src/utils/color';

export const CustomizedBlink: FC = (props: any, height: number) => {
    const { formattedGraphicalItems } = props;
    const firstSeries = formattedGraphicalItems[0];

    const allBlinkPoints = firstSeries?.props?.points.filter(
        (p: any) => p.payload?.blink
    );

    return (
        <>
            {allBlinkPoints.map((blinkPoint: any) => (
                <>
                    <Cross
                        y={blinkPoint?.y}
                        x={blinkPoint?.x}
                        top={20}
                        height={height - 70}
                        stroke={Colors.motion_graph.axis}
                        strokeWidth={2.5}
                        strokeDasharray={'5,5'}
                    />
                    <Cross
                        y={blinkPoint?.y}
                        x={blinkPoint?.x + 7}
                        top={20}
                        height={height - 70}
                        stroke={Colors.motion_graph.axis}
                        strokeWidth={2.5}
                        strokeDasharray={'5,5'}
                    />
                </>
            ))}
        </>
    );
};

export const CustomizedDistort: FC = (props: any, height: number) => {
    const { formattedGraphicalItems } = props;
    const firstSeries = formattedGraphicalItems[0];

    const allDistortPoints = firstSeries?.props?.points.filter(
        (p: any) => p.payload?.distort
    );

    return (
        <>
            {allDistortPoints.map((distortPoint: any) => (
                <>
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.white}
                        strokeWidth={3}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.red}
                        strokeWidth={4}
                        strokeDasharray={'6,2'}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 3}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.white}
                        strokeWidth={3}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 3}
                        top={20}
                        height={height - 70}
                        stroke={Colors.motion_graph.distort_green}
                        strokeWidth={3}
                        strokeDasharray={'6,2'}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 8}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.white}
                        strokeWidth={6}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 8}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.red}
                        strokeWidth={6}
                        strokeDasharray={'6,2'}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 12}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.white}
                        strokeWidth={1}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 12}
                        top={20}
                        height={height - 70}
                        stroke={Colors.motion_graph.distort_green}
                        strokeWidth={1}
                        strokeDasharray={'6,2'}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 15}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.white}
                        strokeWidth={3}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 15}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.red}
                        strokeWidth={3}
                        strokeDasharray={'6,2'}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 19}
                        top={20}
                        height={height - 70}
                        stroke={Colors.extra.white}
                        strokeWidth={4}
                    />
                    <Cross
                        y={distortPoint?.y}
                        x={distortPoint?.x + 19}
                        top={20}
                        height={height - 70}
                        stroke={Colors.motion_graph.distort_green}
                        strokeWidth={4}
                        strokeDasharray={'6,2'}
                    />
                </>
            ))}
        </>
    );
};

export const Identifier = ({ fill }: { fill: string }) => (
    <svg height="10" width="33">
        <path stroke={fill} d="M5 4 l22 0" />
        <circle cx="16" cy="4" r="4" fill={fill} />
    </svg>
);

export const yAxisLabelComponent = (label: string, height: number) => (
    <text
        x={height - 200}
        y={235}
        textAnchor={'middle'}
        fill={Colors.motion_graph.axis_labels}
        transform={'rotate(-90, 30, 235)'}
        fontFamily='"Inter",sans-serif'
    >
        {label}
    </text>
);
