import { FC } from 'react';

import { Props } from 'src/types/motionGraph';
import { Colors } from 'src/utils/color';

import Chart, { LineChartProps } from './charts';
import { ChartContainer, Root } from './styles';

const lines = [
    {
        dataKey: 'afterBlinkFrameRemoval',
        strokeWidth: 3,
        name: 'afterBlinkFrameRemoval',
        stroke: Colors.motion_graph.legend,
        connectNulls: true
    }
];

const MotionGraph: FC<Props> = (props: Props) => {
    const { height, widthPercentage, yAxisLabel, xAxisLabel, data } = props;

    const dataAxisProps: LineChartProps<(typeof data)[number]>['axis'] = {
        x: {
            dataKey: 'time',
            type: 'category',
            allowDataOverflow: true
        }
    };
    return (
        <Root widthPercentage={widthPercentage}>
            <ChartContainer height={height} width={'100%'}>
                <Chart
                    data={data}
                    lines={lines}
                    axis={dataAxisProps}
                    xAxisLabel={xAxisLabel}
                    legend={{
                        hide: true
                    }}
                    gridOptions={{
                        hide: true
                    }}
                    tooltip={{
                        hide: true
                    }}
                    yAxisLabel={yAxisLabel}
                    height={height}
                />
            </ChartContainer>
        </Root>
    );
};

export default MotionGraph;
