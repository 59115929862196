import { FC } from 'react';

import Button from 'src/shared/button';
import Text, { Size } from 'src/shared/text';
import { IInput } from 'src/types/changePasswordModal';
import { Colors } from 'src/utils/color';

import {
    InputContainer,
    InputLabelWrapper,
    StyledInput,
    fontFamily
} from './styled';

export const Input: FC<IInput> = (props) => {
    const { label, value, onChange } = props;
    return (
        <InputContainer>
            <InputLabelWrapper>
                <Text
                    fontFamily={fontFamily}
                    fontSize={Size.XXXSmall}
                    lineHeight="12px"
                    marginBottom={0}
                >
                    {label}
                </Text>
            </InputLabelWrapper>
            <StyledInput type="password" value={value} onChange={onChange} />
        </InputContainer>
    );
};

export const ErrorText: FC<{ text: string }> = ({ text }) => (
    <Text marginBottom={0} color={Colors.extra.red}>
        {text}
    </Text>
);

export const CancelButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
        className="cancel-button"
        backgroundColor={Colors.theme.secondary}
        borderColor={Colors.theme.secondary}
        width="126px"
        height="42px"
        fontSize={Size.XXSmall}
        fontWeight={600}
        color={Colors.extra.black}
        borderRadius="4px"
        onClick={onClick}
    >
        Cancel
    </Button>
);

export const SaveButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Button
        className="save-button"
        width="126px"
        height="42px"
        fontSize={Size.XXSmall}
        fontWeight={600}
        color={Colors.extra.black}
        borderRadius="4px"
        onClick={onClick}
    >
        Save
    </Button>
);
