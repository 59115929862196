import styled from 'styled-components';

import Text from 'src/shared/text';
import { Colors } from 'src/utils/color';

export const Wrapper = styled.div`
    height: 250px;
    width: 100%;
    background-color: ${(props) => props.color};
    margin-top: 10px;
    margin-left: 0px;
    border: dashed;
    border-color: ${Colors.dropdown.dropdown_border};
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

export const TextClick = styled(Text)`
    cursor: pointer;
`;

export const TextFileSize = styled(Text)`
    margin-top: 0;
`;

export const ImagesWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;
    gap: 10px;
`;
