import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<ButtonProps>`
    background-color: ${(props) => props.backgroundColor || '#ffffff'};
    width: ${(props) => props.width || '50px'};
    height: ${(props) => props.height || '50px'};
    border-radius: ${(props) => props.borderRadius || '100px'};
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props) => props.height || 'none'};

    padding: ${(props) => props.padding || '10px'};
    margin-right: ${(props) => props.marginRight};
    ${(props) =>
        props.isBoxShadow
            ? `box-shadow: ${
                  props.boxShadow || '0px 2px 5px rgba(38, 51, 77, 0.03);'
              };`
            : ``}
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    width?: string;
    height?: string;
    padding?: string;
    isBoxShadow?: boolean;
    borderRadius?: string;
    border?: string;
    backgroundColor?: string;
    boxShadow?: string;
    marginRight?: string;
}

type Props = {
    onClick?: () => void;
    disabled?: boolean;
    backgroundColor?: string;
    width?: string;
    height?: string;
    padding?: string;
    isBoxShadow?: boolean;
    borderRadius?: string;
    className?: string;
    boxShadow?: string;
    border?: string;
    icon?: string;
    marginRight?: string;
};

const IconButton: FunctionComponent<Props> = ({
    backgroundColor,
    width,
    height,
    borderRadius,
    padding,
    boxShadow,
    isBoxShadow,
    disabled,
    className,
    border,
    icon,
    onClick,
    marginRight
}: Props) => {
    return (
        <StyledButton
            backgroundColor={backgroundColor}
            width={width}
            height={height}
            borderRadius={borderRadius}
            padding={padding}
            boxShadow={boxShadow}
            isBoxShadow={isBoxShadow}
            disabled={disabled}
            border={border}
            className={className}
            onClick={onClick}
            marginRight={marginRight}
        >
            <Icon src={icon} />
        </StyledButton>
    );
};

export default IconButton;
