import 'bootstrap/dist/css/bootstrap.min.css';
import { FunctionComponent } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import styled from 'styled-components';

import Text, { Size } from '../text';
import { Colors } from 'src/utils/color';

type Props = {
    progress: number;
    totalUnit?: string;
    status?: string;
};

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const LinearProgressBar: FunctionComponent<Props> = ({
    progress,
    totalUnit,
    status
}: Props) => {
    return (
        <ColumnContainer>
            <RowContainer>
                <Text
                    fontSize={Size.XXXSmall}
                    color={Colors.extra.black}
                    fontWeight="400"
                >
                    {totalUnit}
                </Text>{' '}
                <Text
                    marginLeft={8}
                    marginRight={8}
                    fontWeight="400"
                    color={Colors.theme.primary}
                >
                    •
                </Text>
                <Text
                    fontSize={Size.XXXSmall}
                    color={Colors.extra.black}
                    fontWeight="400"
                >
                    {status}
                </Text>
            </RowContainer>
            <ProgressBar style={styles.progressBar} now={progress} />
        </ColumnContainer>
    );
};

export default LinearProgressBar;

const styles: { [key: string]: React.CSSProperties } = {
    progressBar: {
        height: 4
    }
};
