import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<ButtonProps>`
    border: ${(props) => props.border || '1px solid'};
    border-radius: ${(props) => props.borderRadius || '2px'};
    font-size: ${(props) => `${props.fontSize || 13}px`};
    font-weight: ${(props) => props.fontWeight || 'normal'};
    padding: ${(props) => props.padding || '10px 20px'};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    ${(props) =>
        props.disabled
            ? `
               color: ${props.color || '#a4aaaf'};
               background-color: ${props.backgroundColor || '#72996b'};
               border-color: ${props.borderColor || '#72996b'};
           `
            : `
               color: ${props.color || '#fff'};
               background-color: ${props.backgroundColor || 'royalblue'};
               border-color: ${props.borderColor || 'royalblue'};
               cursor: pointer;
           `}
    ${(props) =>
        props.isBoxShadow
            ? `box-shadow: ${props.boxShadow || '0px 2px 0px #5a3dbf'};`
            : ``};
    margin: ${(props) => props.margin};
    background: ${(props) => props.background};
`;

interface Props {
    children?: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    color?: string;
    backgroundColor?: string;
    fontSize?: number;
    width?: string;
    height?: string;
    padding?: string;
    isBoxShadow?: boolean;
    borderRadius?: string;
    borderColor?: string;
    className?: string;
    align?: 'left' | 'center' | 'right';
    fontWeight?: number;
    margin?: string;
    background?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    width?: string;
    height?: string;
    padding?: string;
    isBoxShadow?: boolean;
    borderRadius?: string;
    fontSize?: number;
    border?: string;
    backgroundColor?: string;
    borderColor?: string;
    boxShadow?: string;
    disabled?: boolean;
    fontWeight?: number;
    margin?: string;
    background?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button: FunctionComponent<Props> = ({
    children,
    onClick,
    disabled,
    color,
    backgroundColor,
    fontSize,
    width,
    height,
    padding,
    isBoxShadow,
    borderRadius,
    borderColor,
    className,
    fontWeight,
    margin,
    background,
    type
}: Props) => {
    return (
        <StyledButton
            onClick={onClick}
            disabled={disabled}
            color={color}
            backgroundColor={backgroundColor}
            fontSize={fontSize}
            fontWeight={fontWeight}
            width={width}
            height={height}
            padding={padding}
            isBoxShadow={isBoxShadow}
            borderRadius={borderRadius}
            borderColor={borderColor}
            className={className}
            margin={margin}
            background={background}
            type={type}
        >
            {children}
        </StyledButton>
    );
};

export default Button;
