import styled from 'styled-components';

export const RowView = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding-left: 40px;
    padding-right: 22px;
    margin-top: 20px;
`;

export const ContentView = styled.div`
    width: 100%;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 20px;
    padding-top: 8px;
`;

export const Touchable = styled.div`
    cursor: pointer;
    width: 100%;
`;

export const ColumnView = styled.div`
    display: flex;
    flex-direction: column;
`;
