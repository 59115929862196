import * as yup from 'yup';

import { ChangePasswordData } from 'src/types/changePasswordModal';

export const passwordTypes = [
    {
        label: 'Old Password',
        formikKey: 'oldPassword'
    },
    {
        label: 'New Password',
        formikKey: 'newPassword'
    },
    {
        label: 'Confirm Password',
        formikKey: 'confirmPassword'
    }
];

export const intitalValues: ChangePasswordData = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
};

export const validationSchema = yup.object().shape({
    oldPassword: yup.string().required('Old password is required'),
    newPassword: yup.string().required('New password is required'),
    confirmPassword: yup
        .string()
        .required('Confirm password is required')
        .oneOf([yup.ref('newPassword')], 'Passwords must match')
});
