import { FC, useState } from 'react';

import CustomDropdown from 'src/shared/dropdown';
import Text from 'src/shared/text';
import { CustomPaginationProps } from 'src/types/dataTable';

import { Container, DropdownWrapper, NavButton } from './styled';

const CustomPagination: FC<CustomPaginationProps> = ({
    rowsPerPage,
    rowCount,
    currentPage,
    paginationIconNext,
    paginationIconPrevious,
    onChangeRowsPerPage,
    onChangePage
}) => {
    const [value, setValue] = useState<number>(rowsPerPage);

    const handleRowsPerPage = (selectedValue: number) => {
        setValue(selectedValue);
        onChangeRowsPerPage(selectedValue, currentPage);
    };

    const numPages = Math.ceil(rowCount / rowsPerPage);
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage + 1;
    const range =
        currentPage === numPages
            ? `${firstIndex}-${rowCount} of ${rowCount}`
            : `${firstIndex}-${lastIndex} of ${rowCount}`;

    const handlePrevious = () => onChangePage(currentPage - 1, rowCount);
    const handleNext = () => onChangePage(currentPage + 1, rowCount);

    return (
        <Container>
            <Text marginBottom={2} fontFamily="Inter" fontSize={12}>
                Rows per page:
            </Text>
            <DropdownWrapper>
                <CustomDropdown
                    handleSelect={handleRowsPerPage}
                    options={[
                        { value: 10, label: '10' },
                        { value: 20, label: '20' },
                        { value: 50, label: '50' },
                        { value: 100, label: '100' }
                    ]}
                    selectedValue={value}
                    dropdownWidth={2}
                />
            </DropdownWrapper>
            <Text
                marginBottom={0}
                fontFamily="Inter"
                fontSize={12}
                marginRight={20}
                marginLeft={20}
            >
                {range}
            </Text>
            <NavButton onClick={handlePrevious} disabled={currentPage === 1}>
                {paginationIconPrevious}
            </NavButton>
            <NavButton onClick={handleNext} disabled={currentPage === numPages}>
                {paginationIconNext}
            </NavButton>
        </Container>
    );
};

export default CustomPagination;
