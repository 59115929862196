import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { LockFill, PersonFill } from 'react-bootstrap-icons';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import loginImg from 'src/assets/images/login.png';
import { useAppDispatch } from 'src/hooks';
import { login } from 'src/reducers/user';
import { Button, Input, Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { getAuthToken, setAuthToken } from 'src/utils/auth';
import { Colors } from 'src/utils/color';

import {
    Bottomcontent,
    Bottomterms,
    Cardbodyimg,
    Cardform,
    ForgotPassword,
    Wrapper
} from './styles';

const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .required('Email is required')
            .email('Invalid email'),
        password: yup.string().required('Password is required')
    });
    const [errorMessage, setErrorMessage] = useState<string>('');

    const onSubmit = async (data: any) => {
        dispatch(
            login({
                email: data.email,
                password: data.password
            })
        )
            .unwrap()
            .then((response: any) => {
                if (response?.success) {
                    setAuthToken(response?.data?.auth_token);
                    navigate('/rawdata');
                } else {
                    setErrorMessage(response?.message);
                }
            })
            .catch(() => {
                setErrorMessage('Something went wrong. Please try again.');
            });
    };
    if (getAuthToken()) return <Navigate to="/rawdata" />;
    return (
        <div className="main-row">
            <Wrapper>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <Text
                                    padding={8}
                                    fontSize={Size.Medium}
                                    fontWeight="bold"
                                    color="white"
                                >
                                    Login
                                </Text>
                            </div>
                            <Cardform>
                                <Cardbodyimg>
                                    <img src={loginImg} alt="login-logo" />
                                </Cardbodyimg>
                                <div className="row">
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            password: ''
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={onSubmit}
                                        validateOnMount={false}
                                    >
                                        {({
                                            values,
                                            handleChange,
                                            handleSubmit,
                                            errors,
                                            touched
                                        }) => (
                                            <Form onSubmit={handleSubmit}>
                                                {errorMessage && (
                                                    <Alert variant="danger">
                                                        {errorMessage}
                                                    </Alert>
                                                )}
                                                <Input
                                                    label="Email"
                                                    icon={PersonFill}
                                                    onChange={handleChange(
                                                        'email'
                                                    )}
                                                    type="text"
                                                    name="email"
                                                    value={values.email}
                                                />
                                                {touched.email &&
                                                    errors.email && (
                                                        <Text
                                                            marginLeft={50}
                                                            marginBottom={0}
                                                            color={
                                                                Colors.extra.red
                                                            }
                                                        >
                                                            {errors.email}
                                                        </Text>
                                                    )}
                                                <Input
                                                    label="Password"
                                                    icon={LockFill}
                                                    onChange={handleChange(
                                                        'password'
                                                    )}
                                                    type="password"
                                                    value={values.password}
                                                />
                                                {touched.password &&
                                                    errors.password && (
                                                        <Text
                                                            marginLeft={50}
                                                            marginBottom={0}
                                                            color={
                                                                Colors.extra.red
                                                            }
                                                        >
                                                            {errors.password}
                                                        </Text>
                                                    )}
                                                <Bottomcontent>
                                                    <Bottomterms>
                                                        <Text>
                                                            By logging in, you
                                                            agree to our{' '}
                                                            <Link
                                                                className="tnc-link"
                                                                to="/"
                                                            >
                                                                terms and
                                                                conditions.
                                                            </Link>
                                                        </Text>
                                                    </Bottomterms>
                                                    <div className="btn-sec">
                                                        <Button>LOGIN</Button>
                                                    </div>
                                                </Bottomcontent>
                                                <ForgotPassword>
                                                    <Link to="/forgot-password">
                                                        Forgot Password?
                                                    </Link>
                                                </ForgotPassword>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </Cardform>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Login;
