import { Graph, Trace } from 'src/types/scan';

const negativeOrPositive = () => (Math.round(Math.random()) ? 1 : -1);

export const motionGraphData = () => {
    let a = 0;
    let b = -0.1;
    let c = 0;
    return [...Array(400)].map(() => {
        a += 0.024;
        b += 0.004 * negativeOrPositive();
        c += 0.004 * negativeOrPositive();

        const graphEntry: any = {
            time: a,
            afterBlinkFrameRemoval: b,
            beforeBlinkFrameRemoval: c
        };

        if ((a > 2 && a < 2.05) || (a > 8 && a < 8.05)) {
            graphEntry.blink = true;
        }

        if ((a > 4 && a < 4.05) || (a > 6 && a < 6.05)) {
            graphEntry.distort = true;
        }

        return graphEntry;
    });
};

export const motionGraphDataManipulation = (
    subScansTrace: Array<Trace>,
    type: 'horizontal' | 'vertical'
) => {
    const motionGraph = subScansTrace.map((item) => {
        const graphEntry: Graph = {
            time: item.time_s,
            afterBlinkFrameRemoval:
                type === 'horizontal'
                    ? item.horizontal_motion_deg
                    : item.vertical_motion_deg,
            beforeBlinkFrameRemoval:
                type === 'horizontal'
                    ? item.horizontal_motion_raw_deg
                    : item.vertical_motion_raw_deg
        };

        return graphEntry;
    });
    return motionGraph
};
