import styled from 'styled-components';

import { Colors } from 'src/utils/color';

export const Container = styled.div`
    padding-bottom: 0px;
    & .rdt_Table {
        border-radius: 4px;
        overflow: hidden;
        background-color: ${Colors.theme.secondary};
    }
    & .rdt_TableRow {
        background-color: ${Colors.table.oddrow};
        width: 98%;
        margin: auto;
        cursor: pointer; // Makes the entire row clickable
        &:nth-child(even) { // Alternates the row color
            background-color: ${Colors.table.evenrow};
        }
    }
    & .rdt_TableHeadRow {
        background-color: ${Colors.table.header};
        width: 98%;
        margin: auto;
        border-bottom: 0.5px solid ${Colors.extra.white30};
    }
    & .rdt_TableCol {
        font: 500 12px/15px 'Inter', sans-serif;
        color: black;
    }
    & .rdt_TableCell {
        font: 12px/18px 'Inter', sans-serif;
        color: black;
        :first-child {
        }
    }
    & .rdt_TableRow {
        border-bottom: 0.5px solid ${Colors.extra.light_black};
    }
`;


export const EmptyTable = styled.div`
    background-color: ${Colors.theme.secondary};
    width: 100%;
    text-align: center;
    padding: 20px 0px;
`;
