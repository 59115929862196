import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import {
    CartesianGrid,
    Customized,
    Label,
    Legend,
    Line,
    LineChart,
    LineProps,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import type { Props as XAxisProps } from 'recharts/types/cartesian/XAxis';
import type { Props as YAxisProps } from 'recharts/types/cartesian/YAxis';
import { Props as LegendProps } from 'recharts/types/component/Legend';

import { Colors } from 'src/utils/color';

import { CustomizedBlink, yAxisLabelComponent } from './components';
import { useTooltipSorting } from './hooks/useTooltipSorter';
import { useZoomAndPan } from './hooks/useZoomAndPan';

export type LineChartProps<T extends Record<string, unknown>> = {
    data: T[];
    lines: LineProps[];
    gridOptions?: React.ComponentProps<typeof CartesianGrid> & {
        hide?: boolean;
    };
    axis?: {
        x?: XAxisProps & { hide?: boolean };
        y?: YAxisProps & { hide?: boolean };
    };
    tooltip?: React.ComponentProps<typeof Tooltip<number, string>> & {
        hide?: boolean;
    };
    legend?: LegendProps & { hide?: boolean };
    xAxisLabel: string;
    yAxisLabel: string;
    height: number;
};

const DEFAULT_GRID_PROPS: React.ComponentProps<typeof CartesianGrid> = {
    strokeDasharray: '3 3'
};

const RechartsClipPaths = forwardRef((_, ref: React.ForwardedRef<any>) => {
    const grid = useRef<SVGRectElement>(null);
    const axis = useRef<SVGRectElement>(null);
    useImperativeHandle(ref, () => ({
        grid,
        axis
    }));

    return (
        <>
            <clipPath id="chart-xaxis-clip">
                <rect fill="rgba(0,0,0,0)" height="100%" ref={axis} />
            </clipPath>
            <clipPath id="chart-grid-clip">
                <rect fill="rgba(0,0,0,0)" height="100%" ref={grid} />
            </clipPath>
        </>
    );
});

const Chart = <T extends Record<string, unknown>>({
    data,
    lines,
    gridOptions,
    axis,
    tooltip,
    legend,
    xAxisLabel,
    yAxisLabel,
    height
}: LineChartProps<T>) => {
    const [loaded, setLoaded] = useState(false);

    const {
        clipPathRefs,
        xPadding,
        onChartMouseDown,
        onChartMouseUp,
        setWrapperRef,
        onChartMouseMove
    } = useZoomAndPan({
        chartLoaded: loaded
    });

    const { onSeriesMouseOver, tooltipSorter } = useTooltipSorting();

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 100);
    }, []);

    // Calculate min and max of data
    const dataMin = Math.min(...data.map(item => (item as any)?.afterBlinkFrameRemoval));
    const dataMax = Math.max(...data.map(item => (item as any)?.afterBlinkFrameRemoval));

    // Function to format ticks
    const formatTick = (value: number) => {
        const rounded = Math.round(value * 100) / 100;
        return rounded;
    };
    // Generate ticks based on data range
    const ticks = [];
    for (let i = dataMin; i <= dataMax; i += (dataMax - dataMin) / 5) {
        ticks.push(formatTick(i));
    }
    console.log(data, axis, dataMax, dataMin, ticks)

    return (
        <ResponsiveContainer
            className="noselect"
            width="100%"
            height="100%"
            debounce={100}
            ref={setWrapperRef}
        >
            <LineChart
                data={data}
                onMouseMove={onChartMouseMove}
                onMouseDown={onChartMouseDown}
                onMouseUp={onChartMouseUp}
                margin={{
                    top: 20,
                    bottom: 20,
                    left: 15,
                    right: 20
                }}
            >
                <defs>
                    <RechartsClipPaths ref={clipPathRefs} />
                </defs>
                <CartesianGrid
                    {...{
                        ...(DEFAULT_GRID_PROPS as any),
                        ...gridOptions,
                        stroke: gridOptions?.hide
                            ? 'transparent'
                            : gridOptions?.stroke
                    }}
                />
                {axis?.x?.hide ? null : (
                    <XAxis
                        dataKey="time"
                        type="number"
                        domain={[0, 10]}
                        tickCount={
                            (Math.abs(xPadding[0]) + Math.abs(xPadding[1])) /
                                100 +
                            10
                        }
                        fill={Colors.motion_graph.axis}
                        strokeWidth={4}
                        fontFamily='"Inter",sans-serif'
                        fontSize={12}
                        allowDataOverflow={true}
                        padding={{ left: xPadding[0], right: xPadding[1] }}
                        label={{
                            value: xAxisLabel,
                            offset: -10,
                            position: 'insideBottom',
                            textAnchor: 'middle',
                            fill: Colors.motion_graph.axis_labels,
                            fontFamily: '"Inter",sans-serif'
                        }}
                    >
                        <Label
                            content={() =>
                                yAxisLabelComponent(yAxisLabel, height)
                            }
                        />
                    </XAxis>
                )}
                {axis?.y?.hide ? null : (
                    <YAxis
                        {...axis?.y}
                        ticks={ticks}
                        type="number"
                        fill={Colors.motion_graph.axis}
                        strokeWidth={4}
                        fontFamily='"Inter",sans-serif'
                        fontSize={12}
                    >
                        <Label
                            content={() =>
                                yAxisLabelComponent(yAxisLabel, height)
                            }
                        />
                    </YAxis>
                )}
                {tooltip?.hide ? null : (
                    <Tooltip {...tooltip} itemSorter={tooltipSorter} />
                )}
                {legend?.hide ? null : <Legend {...(legend as any)} />}
                {lines.map((l, i) => (
                    <Line
                        key={`${l.key}-${i}`}
                        id={l.key}
                        {...(l as any)}
                        className={`${l.className || ''}`}
                        activeDot={{
                            onMouseOver: () =>
                                onSeriesMouseOver(String(l.dataKey))
                        }}
                        onMouseOver={() => onSeriesMouseOver(String(l.dataKey))}
                        dot={false}
                        isAnimationActive={true}
                        type="monotone"
                    />
                ))}
                {xPadding[0] === 0 && xPadding[1] === 0 && (
                    <Customized
                        component={(prop: any) => CustomizedBlink(prop, height)}
                    />
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default Chart;
