import React, { useEffect, useState } from 'react';

import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';

import { SearchInput, Wrapper } from './styled';

interface Props {
    onChange?: (value: string) => void;
    onSubmit: (value: string) => void;
    value?: string;
}

const SearchBox = (props: Props) => {
    const [val, setVal] = useState('');

    useEffect(() => {
        const { value } = props;
        setVal(value || '');
    }, [props, props.value]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            props.onSubmit(val);
        }
    };

    return (
        <Wrapper>
            <SearchInput
                placeholder="Search"
                onChange={(event) => {
                    setVal(event.target.value);
                    props?.onChange && props?.onChange(event.target.value);
                }}
                onKeyDown={handleKeyDown}
                value={val}
            />
            <SearchIcon />
        </Wrapper>
    );
};

export default SearchBox;
