import moment from 'moment';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useAppDispatch } from 'src/hooks';
import { UserSelectors, getPatientList } from 'src/reducers/user';
import { DashboardWrapper, DataTable, SearchInput, Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { getAuthToken } from 'src/utils/auth';

import { CellContent } from './components';
import { ColumnView, ContentView, RowView, Touchable } from './styles';

const CellComponent: FunctionComponent<{ row: any }> = ({ row }) => {
    const navigate = useNavigate();

    const handleCellClick = () => {
        const state = { name: row.full_name };
        navigate(`/patient/${row.id}/scan`, { state });
    };

    return (
        <Touchable onClick={handleCellClick}>
            <CellContent value={row.full_name} />
        </Touchable>
    );
};

const columns = [
    {
        name: 'Name',
        center: true,
        selector: (row: any) => row.full_name,
        button: true,
        cell: (row: any) => <CellComponent row={row} />
    },
    {
        name: 'Scan ID',
        center: true
    },
    {
        name: 'Last visit',
        center: true,
        cell: (row: any) => (
            <ColumnView>
                <Text
                    fontSize={Size.XXXSmall}
                    lineHeight="18px"
                    fontWeight="400"
                    textAlign="center"
                    fontFamily="Inter"
                    marginBottom={0}
                >
                    {moment(row.last_visit).format('MMM DD, YYYY')}
                </Text>
                <Text
                    fontSize={Size.XXXSmall}
                    lineHeight="18px"
                    fontWeight="400"
                    textAlign="center"
                    fontFamily="Inter"
                    marginBottom={0}
                >
                    {moment(row.last_visit).format('LT')}
                </Text>
            </ColumnView>
        )
    },
    {
        name: 'Medical examination summary',
        wrap: true,
        center: true
    },
    {
        name: 'Description',
        center: true,
        wrap: true
    }
];

type Props = Record<string, never>;

const Patient: FunctionComponent<Props> = ({}: Props) => {
    const dispatch = useAppDispatch();
    const { patients } = UserSelectors();
    const [limit, setLimit] = useState(10);
    const [searchInput, setSearchInput] = useState<string>('');
    const [searchInputDebounced] = useDebounce(searchInput, 1000);

    useEffect(() => {
        if (searchInputDebounced) {
            dispatch(
                getPatientList({
                    page: 1,
                    limit: limit,
                    search: searchInputDebounced
                })
            );
        } else {
            dispatch(getPatientList({ page: 1, limit: limit }));
        }
    }, [dispatch, limit, searchInputDebounced]);

    const onChangePage = useCallback(
        (page: number) => {
            if (searchInputDebounced) {
                dispatch(
                    getPatientList({
                        page,
                        limit: limit,
                        search: searchInputDebounced
                    })
                );
            } else {
                dispatch(
                    getPatientList({
                        page,
                        limit: limit
                    })
                );
            }
        },
        [dispatch, limit, searchInputDebounced]
    );

    const onChangeRowsPerPage = (currentRowsPerPage: number) => {
        setLimit(currentRowsPerPage);
    };

    const onSubmitSearchInput = (value: string) => {
        setSearchInput(value);
    };

    const onChangeSearchInput = (value: string) => {
        setSearchInput(value);
    };

    if (!getAuthToken()) return <Navigate to="/" />;
    return (
        <DashboardWrapper>
            <RowView>
                <Text fontWeight="600" marginLeft={8} fontSize={Size.XSmall}>
                    Patient List
                </Text>
                <SearchInput
                    value={searchInput}
                    onSubmit={onSubmitSearchInput}
                    onChange={onChangeSearchInput}
                />
            </RowView>
            <ContentView>
                <DataTable
                    key={searchInputDebounced}
                    columns={columns}
                    data={patients.patients}
                    perPageLimit={limit}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    paginationTotalRows={patients.total_count}
                />
            </ContentView>
        </DashboardWrapper>
    );
};

export default Patient;
