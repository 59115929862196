import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice
} from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import * as api from '../services/api';
import {
    retrieveUserProfile,
    storeUserProfile
} from '../services/sessionStorage';
import { RootState } from '../store';
import { PatientList, UserProfile } from '../types/user';

const userAdapter = createEntityAdapter();

export interface UserState {
    loading: boolean;
    profile: UserProfile | null;
    patients: PatientList;
}

const initialState = userAdapter.getInitialState({
    loading: false,
    profile: retrieveUserProfile(),
    patients: {
        patients: [],
        total_count: 0,
        page: 1
    }
} as UserState);

export const login = createAsyncThunk(
    'user/login',
    async (
        _options: { email: string; password: string },
        { rejectWithValue }
    ) => {
        try {
            return await api.login(_options.email, _options.password);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const changePassword = createAsyncThunk(
    'user/changePassword',
    async (
        _options: { email: string; old_password: string; new_password: string },
        { rejectWithValue }
    ) => {
        try {
            return await api.changePassword(
                _options.email,
                _options.old_password,
                _options.new_password
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const verifyToken = createAsyncThunk(
    'user/verifyToken',
    async (_options: { token: string }, { rejectWithValue }) => {
        try {
            return await api.verifyToken(_options.token);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (
        _options: { token: string; password: string },
        { rejectWithValue }
    ) => {
        try {
            return await api.resetPassword(_options.token, _options.password);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const forgetPassword = createAsyncThunk(
    'user/forgetPassword',
    async (_options: { email: string }, { rejectWithValue }) => {
        try {
            return await api.forgetPassword(_options.email);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getPatientList = createAsyncThunk(
    'user/getPatientList',
    async (
        _options: { page: number; limit: number; search?: string },
        { rejectWithValue }
    ) => {
        try {
            return await api.getPatients(
                _options.page,
                _options.limit,
                _options.search
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.profile = null;
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            state.loading = false;
            const user = { ...payload.data };
            delete user.auth_token;
            state.profile = user;
            storeUserProfile(user);
        });
        builder.addCase(login.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getPatientList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPatientList.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.patients = payload.data;
        });
        builder.addCase(getPatientList.rejected, (state) => {
            state.loading = false;
        });
    }
});

export default userSlice.reducer;

interface UserSelectorsType {
    loading: boolean;
    profile: UserProfile | null;
    patients: PatientList;
}

export const UserSelectors = (): UserSelectorsType => {
    const loading = useSelector((state: RootState) => state.user.loading);
    const profile = useSelector((state: RootState) => state.user.profile);
    // TODO: Remove patients
    const patients = useSelector((state: RootState) => state.user.patients);

    return {
        profile,
        loading,
        patients
    };
};
