import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../../hooks';
import {
    ScanSelector,
    getSubScanPreview,
    getSubScanSummary,
    getSubScanTrace,
    getSubScans
} from 'src/reducers/scan';
import {
    Card,
    DashboardWrapper,
    Dropdown,
    Switch,
    Text,
    VideoPlayer
} from 'src/shared';
import DatePicker from 'src/shared/datePicker';
import { Graph } from 'src/types/scan';
import { getAuthToken } from 'src/utils/auth';

import { Loader, MotionGraph, StatisticsCard, MetadataCard, Summary } from './components';
import { motionGraphDataManipulation } from './components/motionGraph/helpers';
import {
    ContentView,
    HeaderView,
    Label,
    LabelContainer,
    RowView,
    Wrapper
} from './styles';
import { saveAs } from 'file-saver';

type Props = Record<string, never>;
type BreakpointsState = {
    at1440: boolean;
};

const trackOption = [
    { value: 0, label: 'Eye tracking' },
    { value: 1, label: 'Fixation' },
    { value: 2, label: 'Montage' }
];

enum SubScanMode {
    'Fixation mode 1' = 1,
    'Saccade tracking mode 3' = 3
}

const downloadDataAsCSV = (data: Graph[], filename: string) => {
    // Extract object keys and use them as CSV headers
    const headers = Object.keys(data[0]);
    const csvContent = [
        headers.join(","),
        ...data.map(obj => headers.map(key => obj[key]).join(","))
    ].join("\n");

    var blob = new Blob([csvContent], {type: "text/csv;charset=utf-8"});
    saveAs(blob, filename);
}

const downloadDataAsJSON = (data: Graph[], filename: string) => {
    var blob = new Blob([JSON.stringify(data)], {type: "application/json"});
    saveAs(blob, filename);
}

const Report: FunctionComponent<Props> = (_: Props) => {
    const dispatch = useAppDispatch();

    const { patientId, scanId } = useParams();
    const { loading, subScans, subScanPreview, subScanTrace, subScanSummary } =
        ScanSelector();

    const [isSwitchOn, setSwitchOn] = useState(false);
    const [isSwitchDisabled, setSwitchDisabled] = useState(false);
    const [availableModes, setAvailableModes] = useState<number[]>([]);
    const [availableScans, setAvailableScans] = useState<number[]>([]);
    const [selectedMode, setSelectedMode] = useState<number | undefined>();
    const [selectedScan, setSelectedScan] = useState<number | undefined>();
    const [selectedTrack, setSelectedTrack] = useState(trackOption[0].value);
    const [horizontalGraphData, setHorizontalGraphData] = useState<
        Array<Graph>
    >([]);
    const [verticalGraphData, setVerticalGraphData] = useState<Array<Graph>>(
        []
    );

    const [, setDate] = useState({
        selectedRange: '',
        startDate: new Date(),
        endDate: new Date()
    });

    useEffect(() => {
        if (subScanTrace) {
            const horiData = motionGraphDataManipulation(
                subScanTrace,
                'horizontal'
            );
            const vertiData = motionGraphDataManipulation(
                subScanTrace,
                'vertical'
            );
            setHorizontalGraphData(horiData);
            setVerticalGraphData(vertiData);
        } else {
            setHorizontalGraphData([]);
            setVerticalGraphData([]);
        }
    }, [subScanTrace]);

    useEffect(() => {
        dispatch(
            getSubScans({
                patientId: patientId || '',
                scanId: scanId || ''
            })
        );
    }, [dispatch, patientId, scanId]);

    useEffect(() => {
        if (
            patientId !== undefined &&
            scanId !== undefined &&
            selectedMode !== undefined &&
            selectedScan !== undefined
        ) {
            const odos = isSwitchOn ? 1 : 0;

            dispatch(
                getSubScanSummary({
                    patientId: patientId,
                    scanId: scanId,
                    mode: selectedMode,
                    odos,
                    scan: selectedScan
                })
            );

            dispatch(
                getSubScanTrace({
                    patientId: patientId,
                    scanId: scanId,
                    mode: selectedMode,
                    odos,
                    scan: selectedScan
                })
            );

            dispatch(
                getSubScanPreview({
                    patientId: patientId,
                    scanId: scanId,
                    mode: selectedMode,
                    odos,
                    scan: selectedScan
                })
            );
        }
    }, [patientId, scanId, isSwitchOn, selectedMode, selectedScan, dispatch]);

    useEffect(() => {
        // set default switch and dropdown values
        if (subScans.length > 0) {
            const subScanOdOsOptions = [
                ...new Set(subScans.map((subScan) => subScan.odos))
            ];

            setSwitchOn(subScans[0].odos ? true : false);

            // if only one odos value is available disable the switch
            setSwitchDisabled(subScanOdOsOptions.length < 2);

            const subScanModeOptions = [
                ...new Set(subScans.map((subScan) => subScan.mode))
            ];
            const subScanNumberOptions = [
                ...new Set(subScans.map((subScan) => subScan.scan_number))
            ];

            setAvailableModes(subScanModeOptions);
            setAvailableScans(subScanNumberOptions);

            // set mode and scan number options
            setSelectedMode(subScanModeOptions[0]);
            setSelectedScan(subScanNumberOptions[0]);
        } else {
            setSwitchDisabled(true);
        }
    }, [subScans]);

    const handleModeSelect = (selectedValue: number) => {
        setSelectedMode(selectedValue);
    };

    const handleScanSelect = (selectedValue: number) => {
        setSelectedScan(selectedValue);
    };

    const handleDateChange = (
        selectedRange: string,
        startDate: Date,
        endDate: Date
    ) => {
        setDate({
            selectedRange: selectedRange,
            startDate: startDate,
            endDate: endDate
        });
    };

    const handleTrackSelect = (selectedValue: number) => {
        setSelectedTrack(selectedValue);
    };

    const [breakpoints, setBreakpoints] = useState<BreakpointsState>({
        at1440: false
    });

    const handleWindowResize = () => {
        const windowWidth = window.innerWidth;
        switch (true) {
            case windowWidth < 1440:
                setBreakpoints({ at1440: true });
                break;
            default:
                setBreakpoints({ at1440: false });
                return;
        }
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const modeOptions = useMemo(() => {
        return availableModes.map((mode) => ({
            value: mode,
            label: SubScanMode[mode]
        }));
    }, [availableModes]);

    const scanOptions = useMemo(() => {
        return availableScans.map((scan) => ({
            value: scan,
            label: `Scan ${scan}`
        }));
    }, [availableScans]);

    if (!getAuthToken()) return <Navigate to="/" />;

    return (
        <Wrapper>
            <DashboardWrapper>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <HeaderView>
                            <RowView>
                                <Switch
                                    offText="OD 0"
                                    onText="OS 1"
                                    value={isSwitchOn}
                                    onChange={setSwitchOn}
                                    isDisabled={isSwitchDisabled}
                                />
                                <Dropdown
                                    options={modeOptions}
                                    marginLeft={10}
                                    handleSelect={handleModeSelect}
                                    selectedValue={selectedMode}
                                />
                                <Dropdown
                                    options={scanOptions}
                                    handleSelect={handleScanSelect}
                                    marginLeft={10}
                                    selectedValue={selectedScan}
                                />
                            </RowView>
                            <RowView>
                                <DatePicker onSelect={handleDateChange} />
                                <Dropdown
                                    options={trackOption}
                                    marginLeft={10}
                                    handleSelect={handleTrackSelect}
                                    selectedValue={selectedTrack}
                                />
                            </RowView>
                        </HeaderView>
                        <ContentView>
                        <Col lg={12} xl={3}>
                                <StatisticsCard summaryData={subScanSummary} />
                            </Col>
                            <Col lg={12} xl={3}>
                                <MetadataCard summaryData={subScanSummary} />
                            </Col>
                            <Col lg={12} xl={6}>
                                <Summary summaryData={subScanSummary} />
                            </Col>
                        </ContentView>
                        <ContentView>
                            <Col lg={12} xl={7}>
                                <Card
                                    height={
                                        breakpoints.at1440 ? undefined : '411px'
                                    }
                                >
                                    <LabelContainer
                                        flexDirection={
                                            breakpoints.at1440
                                                ? 'column'
                                                : 'row'
                                        }
                                    >
                                        <Label>
                                            <Text>Vertical motion</Text>
                                            <MotionGraph
                                                height={320}
                                                widthPercentage={90}
                                                yAxisLabel="Vertical Motion [Deg]"
                                                data={verticalGraphData}
                                                xAxisLabel="Time [s]"
                                            />
                                            <button onClick={() => downloadDataAsCSV(verticalGraphData, 'vertical_data.csv')}>↓ CSV</button>
                                            <button onClick={() => downloadDataAsJSON(verticalGraphData, 'vertical_data.json')}>↓ JSON</button>
                                        </Label>
                                        <Label>
                                            <Text>Horizontal motion</Text>
                                            <MotionGraph
                                                height={320}
                                                widthPercentage={90}
                                                data={horizontalGraphData}
                                                yAxisLabel="Horizontal Motion [Deg]"
                                                xAxisLabel="Time [s]"
                                            />
                                        <button onClick={() => downloadDataAsCSV(horizontalGraphData, 'horizontal_data.csv')}>↓ CSV</button>
                                        <button onClick={() => downloadDataAsJSON(horizontalGraphData, 'horizontal_data.json')}>↓ JSON</button>                                        </Label>
                                    </LabelContainer>
                                </Card>
                            </Col>
                            <Col lg={12} xl={5}>
                                <Card height="411px" className="player-view">
                                    <VideoPlayer
                                        backgroundColor="transparent"
                                        url={subScanPreview}
                                    />
                                </Card>
                            </Col>
                        </ContentView>
                    </>
                )}
            </DashboardWrapper>
        </Wrapper>
    );
};

export default Report;
