import { ReactElement } from 'react';

import { Ticks } from 'src/types/radar';
import { Colors } from 'src/utils/color';

export const calculatePolarAngleAxisTickPositionY = (props: any): number => {
    if (props.index !== 0) {
        if (props.textAnchor === 'middle') {
            return props.y + 10;
        }
        return props.y + 3;
    }
    return props.y;
};

export const polarAngleAxisTick = (props: any): ReactElement => (
    <text
        x={props.x}
        y={calculatePolarAngleAxisTickPositionY(props)}
        textAnchor={props.textAnchor}
        fill={Colors.extra.black}
        fontFamily='"Inter",sans-serif'
        fontSize={'12px'}
    >
        {props.payload.value}
    </text>
);

export const polarRadiusAxisTick = (props: any, ticks: Ticks): ReactElement => (
    <text
        x={props.x}
        y={props.y}
        textAnchor={props.textAnchor}
        fill={Colors.radar.yellow}
        fontFamily='"Inter",sans-serif'
        fontSize={'8px'}
    >
        {['', ...Object.values(ticks), ''][props.index]}
    </text>
);

export const evalRadarFill = (type: string): string =>
    type === 'filled' ? Colors.radar.blue1 : Colors.radar.blue2;
