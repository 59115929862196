import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: 203px;
    border-bottom: 0.5px solid #1e1e1e;
    padding-bottom: 7px;
    height: 35px;
`;

export const SearchInput = styled.input`
    border: none;
    outline: none;
    width: 175px;
    margin-right: 10px;
    background: transparent;
    ::placeholder,
    ::-webkit-input-placeholder {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
    }
    :-ms-input-placeholder {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
    }
`;
