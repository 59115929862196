import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 40px auto;
    max-width: 700px;

    .card-header {
        background-color: #393232;
        height: 60px;
    }

    .card-header h4 {
        color: #fff;
        margin: 0;
        padding: 15px;
    }

    @media (max-width: 991px) {
        max-width: 600px;
    }

    @media (max-width: 768px) {
        max-width: 500px;
    }

    @media (max-width: 500px) {
        margin: 10px auto;
    }
`;

export const Cardform = styled.div`
    margin: 10px;

    .form-label {
        color: #bcbcbc;
        font-size: 12px;
        margin-left: 35px;

        @media (max-width: 500px) {
            font-size: 10px;
        }
    }

    .form-content {
        margin-left: 5px;
        margin-right: 5px;
        display: flex;
        align-items: end;
    }

    .form-content .form-icon {
        font-size: 25px;
        margin-bottom: 5px;
        color: gray;

        @media (max-width: 500px) {
            font-size: 25px;
            padding: 15px 5px 0 0px;
        }

        @media (max-width: 425px) {
            font-size: 20px;
        }
    }

    .input-field {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }
    .bottom-border {
        border-bottom: 1px solid grey;
        display: flex;
        flex: 1;
        margin-left: 10px;
    }

    .form-input {
        border: none;
    }

    .form-content .input-field .form-input {
        background-color: aliceblue;
        color: #000;
        outline: none;

        @media (max-width: 500px) {
            padding: 8px 2px;
        }
    }

    .form-content .form-input::placeholder {
        color: #000;
    }

    .btn-login {
        background-color: black;
    }

    @media (max-width: 425px) {
        margin: 0;
    }
`;

export const Cardbodyimg = styled.div`
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
        margin: 15px 0px;
    }

    img {
        width: 70%;

        @media (max-width: 767px) {
            width: 60%;
        }
    }
`;

export const Bottomcontent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 567px) {
        margin-top: 30px;
    }

    Button {
        background-color: royalblue;
        outline: none;
        padding: 10px 20px;
        border: none;
        border-radius: 2px;
        color: #fff;
        cursor: pointer;

        @media (max-width: 567px) {
            padding: 8px 16px;
        }
    }
`;

export const Bottomterms = styled.div`
    padding-left: 10px;

    p {
        font-size: 15px;
    }

    @media (max-width: 567px) {
        p {
            font-size: 14px;
        }
    }

    @media (max-width: 425px) {
        p {
            font-size: 11px;
        }
    }
`;

export const ForgotPassword = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
