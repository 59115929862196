import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice
} from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import * as api from '../services/api';
import { RootState } from '../store';
import { SubScan, SubScanSummary, Trace } from 'src/types/scan';

const scanAdapter = createEntityAdapter();

interface ScanState {
    loading: boolean;
    subScans: Array<SubScan>;
    subScanPreview: string | null;
    subScanTrace: Array<Trace>;
    subScanSummary: SubScanSummary | null;
}

const initialState = scanAdapter.getInitialState({
    loading: false,
    subScans: [],
    subScanPreview: null,
    subScanTrace: [],
    subScanSummary: null
} as ScanState);

export const getScanList = createAsyncThunk(
    'user/getScanList',
    async (
        options: {
            page: number;
            limit: number;
            patientId: string;
            search?: string;
        },
        { rejectWithValue }
    ) => {
        try {
            return await api.getPatientScans(
                options.patientId,
                options.page,
                options.limit,
                options.search
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSubScans = createAsyncThunk(
    'user/getSubScan',
    async (
        options: { patientId: string; scanId: string },
        { rejectWithValue }
    ) => {
        try {
            return await api.getSubScans(options.patientId, options.scanId);
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSubScanSummary = createAsyncThunk(
    'scan/getSubScanSummary',
    async (
        options: {
            patientId: string;
            scanId: string;
            mode: number;
            odos: number;
            scan: number;
        },
        { rejectWithValue }
    ) => {
        try {
            return await api.getSubScanSummary(
                options.patientId,
                options.scanId,
                options.mode,
                options.odos,
                options.scan
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSubScanTrace = createAsyncThunk(
    'scan/getSubScanTrace',
    async (
        options: {
            patientId: string;
            scanId: string;
            mode: number;
            odos: number;
            scan: number;
        },
        { rejectWithValue }
    ) => {
        try {
            return await api.getSubScanTrace(
                options.patientId,
                options.scanId,
                options.mode,
                options.odos,
                options.scan
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getSubScanPreview = createAsyncThunk(
    'scan/getSubScanPreview',
    async (
        options: {
            patientId: string;
            scanId: string;
            mode: number;
            odos: number;
            scan: number;
        },
        { rejectWithValue }
    ) => {
        try {
            return await api.getSubScanPreview(
                options.patientId,
                options.scanId,
                options.mode,
                options.odos,
                options.scan
            );
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

const scanSlice = createSlice({
    name: 'scan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getScanList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getScanList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(getScanList.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getSubScans.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubScans.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.subScans = payload.data;
        });
        builder.addCase(getSubScans.rejected, (state) => {
            state.loading = false;
            state.subScans = [];
        });
        builder.addCase(getSubScanPreview.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubScanPreview.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.subScanPreview = payload.data?.url || null;
        });
        builder.addCase(getSubScanPreview.rejected, (state) => {
            state.loading = false;
            state.subScanPreview = null;
        });
        builder.addCase(getSubScanTrace.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubScanTrace.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.subScanTrace = payload.data;
        });
        builder.addCase(getSubScanTrace.rejected, (state) => {
            state.loading = false;
            state.subScanTrace = [];
        });
        builder.addCase(getSubScanSummary.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubScanSummary.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.subScanSummary = payload.data;
        });
        builder.addCase(getSubScanSummary.rejected, (state) => {
            state.loading = false;
            state.subScanSummary = null;
        });
    }
});

export default scanSlice.reducer;

interface ScanSelectorsType {
    loading: boolean;
    subScans: Array<SubScan>;
    subScanPreview: string | null;
    subScanTrace: Array<Trace> | null;
    subScanSummary: SubScanSummary | null;
}

export const ScanSelector = (): ScanSelectorsType => {
    const loading = useSelector((state: RootState) => state.scan.loading);

    const subScans = useSelector((state: RootState) => state.scan.subScans);

    const subScanPreview = useSelector(
        (state: RootState) => state.scan.subScanPreview
    );

    const subScanTrace = useSelector(
        (state: RootState) => state.scan.subScanTrace
    );

    const subScanSummary = useSelector(
        (state: RootState) => state.scan.subScanSummary
    );

    return {
        loading,
        subScans,
        subScanPreview,
        subScanTrace,
        subScanSummary
    };
};
