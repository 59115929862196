import { FunctionComponent } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import styled from 'styled-components';

import { Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { Colors } from 'src/utils/color';

const Container = styled.div<Props>`
    display: flex;
    justify-content: space-around;
    width: 100%;
`;

const CircularProgressContainer = styled.div<Props>`
    text-align: center;
`;

const TooltipContainer = styled.div<Props>`
    position: relative;
    bottom: 29px;
    right: 45px;
    box-shadow: -1px 5px 23px 1px rgba(94, 94, 94, 0.75);
    -webkit-box-shadow: -1px 5px 23px 1px rgba(94, 94, 94, 0.75);
    -moz-box-shadow: -1px 5px 23px 1px rgba(94, 94, 94, 0.75);
`;

const tooltipStyle = {
    contentStyle: {
        backgroundColor: Colors.extra.label_bg,
        border: 'none',
        borderRadius: '4px',
        padding: '0px 6px 0px 6px',
        display: 'flex'
    },
    focus: {
        outline: 'none'
    }
};

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
}

const CustomTooltip: FunctionComponent<CustomTooltipProps> = ({
    active,
    payload
}) => {
    if (active && payload && payload.length) {
        const data = payload[0]; // Assuming only one data item in payload, modify accordingly if needed
        const { value, name } = data;

        return (
            <TooltipContainer style={tooltipStyle.contentStyle}>
                <Text
                    color={Colors.extra.white}
                    fontWeight="600"
                    fontSize={Size.XXXSmall}
                >
                    {name}
                </Text>
                <Text
                    marginLeft={4}
                    fontWeight="600"
                    fontSize={Size.XXXSmall}
                    color={Colors.extra.black}
                >
                    {value}%
                </Text>
            </TooltipContainer>
        );
    }

    return null;
};

interface Props {
    title?: string;
    subTitle?: string;
    data?: { name: string; value: number }[];
}

const list = [
    { name: 'Some data', value: 52 },
    { name: 'Some data', value: 48 }
];

const COLORS = [`${Colors.extra.cell_bg_one}`, `${Colors.extra.cell_bg_two}`];

const CircularProgress: FunctionComponent<Props> = ({
    title,
    subTitle,
    data = list
}: Props) => {
    return (
        <Container>
            <PieChart width={120} height={120}>
                <Pie
                    style={tooltipStyle.focus}
                    data={data}
                    cx={50}
                    cy={50}
                    innerRadius={38}
                    outerRadius={55}
                    dataKey="value"
                    startAngle={-90}
                >
                    {data.map((entry: any, index: any) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                            strokeWidth={0}
                        />
                    ))}
                </Pie>
                <Tooltip
                    content={<CustomTooltip />}
                    isAnimationActive={false}
                />
            </PieChart>
            <CircularProgressContainer>
                <Text
                    fontSize={Size.XXSmall}
                    fontWeight="700"
                    lineHeight="17px"
                    textAlign="center"
                    marginBottom={0}
                >
                    {title}
                </Text>
                <Text
                    fontSize={Size.XXXSmall}
                    fontWeight="400"
                    lineHeight="17px"
                    marginTop={0}
                >
                    {subTitle}
                </Text>
            </CircularProgressContainer>
        </Container>
    );
};

export default CircularProgress;
