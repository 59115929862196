export const setAuthToken = (token: string) => {
    return sessionStorage.setItem('token', token);
};

export const getAuthToken = () => {
    return sessionStorage.getItem('token');
};

export const clearAuthToken = () => {
    return sessionStorage.removeItem('token');
};

export const AUTHORIZATION_HEADER_NAME = 'X-Authorization';
