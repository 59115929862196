import { UserProfile } from '../types/user';

const KEY_PREFIX = 'SESSION_';

const storeValue = (key: string, value: string): void => {
    sessionStorage.setItem(`${KEY_PREFIX}${key}`, value);
};

const retrieveValue = (key: string): string | null => {
    return sessionStorage.getItem(`${KEY_PREFIX}${key}`);
};

const clearAll = (): void => {
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key?.startsWith(KEY_PREFIX)) {
            sessionStorage.removeItem(key);
        }
    }
};

const storeUserProfile = (user: UserProfile) => {
    return storeValue('user_profile', JSON.stringify(user));
};

const retrieveUserProfile = (): UserProfile | null => {
    const storedProfile = retrieveValue('user_profile');

    if (storedProfile) {
        return JSON.parse(storedProfile);
    } else {
        return null;
    }
};

export { clearAll, retrieveUserProfile, storeUserProfile };
