import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { Colors } from 'src/utils/color';

const Content = styled.div`
    padding: 10px;
`;

const Button = styled.button`
    margin: 0px;
    background: #9b9ea9;
    border: none;
    text-decoration: underline;
    color: ${Colors.theme.primary};
    padding: 0px;
    margin-left: 3px;
`;

const readMoreLimit = 50;

type Props = {
    value: string;
};

const CellContent: FunctionComponent<Props> = ({ value }: Props) => {
    const [readMore, setReadMore] = useState(false);

    return (
        <Content>
            {readMore ? value : value.substring(0, readMoreLimit)}
            {value.length > readMoreLimit && (
                <Button onClick={() => setReadMore(!readMore)}>
                    {readMore ? 'Read Less' : 'Read More'}
                </Button>
            )}
        </Content>
    );
};

export default CellContent;
