export const radarData = {
    minValue: 0,
    maxValue: 100,
    ticks: {
        tick1: 0.5,
        tick2: 1.0,
        tick3: 1.5
    },
    list: [
        {
            anchor: '90',
            value: 20
        },
        {
            anchor: '45',
            value: 19
        },
        {
            anchor: '0',
            value: 18
        },
        {
            anchor: '315',
            value: 17
        },
        {
            anchor: '270',
            value: 16
        },
        {
            anchor: '225',
            value: 15
        },
        {
            anchor: '180',
            value: 14
        },
        {
            anchor: '135',
            value: 13
        }
    ]
};
