import { FC } from 'react';
import {
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar as RDR,
    RadarChart
} from 'recharts';

import { Props } from 'src/types/radar';
import { Colors } from 'src/utils/color';

import { radarData } from './constants';
import {
    evalRadarFill,
    polarAngleAxisTick,
    polarRadiusAxisTick
} from './helpers';
import { Container } from './styles';

const Radar: FC<Props> = (props: Props) => {
    const { data = radarData, type = 'filled' } = props;

    return (
        <Container radarType={type}>
            <RadarChart width={261} height={215} data={data.list}>
                <PolarGrid gridType="circle" stroke="black" />
                <PolarAngleAxis dataKey="anchor" tick={polarAngleAxisTick} />
                <PolarRadiusAxis
                    angle={90}
                    tick={(t) => polarRadiusAxisTick(t, data.ticks)}
                    axisLine={false}
                    domain={[data.minValue, data.maxValue]}
                />
                <RDR
                    name="Name"
                    dataKey="value"
                    fill={evalRadarFill(type)}
                    stroke={Colors.radar.blue1}
                    dot={type === 'dotted'}
                />
            </RadarChart>
        </Container>
    );
};

export default Radar;
