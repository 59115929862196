import { Form, Formik, FormikHelpers } from 'formik';
import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { useAppDispatch } from 'src/hooks';
import { forgetPassword } from 'src/reducers/user';
import { Button, Text } from 'src/shared';
import { Size } from 'src/shared/text';
import { Colors } from 'src/utils/color';

import {
    ButtonsWrapper,
    InputContainer,
    InputLabelWrapper,
    Separator,
    StyledInput,
    StyledModalBody,
    Wrapper
} from './styles';

type Props = Record<string, never>;

const ForgotPassword: FunctionComponent<Props> = ({}: Props) => {
    const [successMessage, setSuccessMessage] = useState(false);

    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                setSuccessMessage(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const validationSchema = yup.object().shape({
        email: yup.string().required('Email is required').email('Invalid email')
    });

    const ThankyouModal = () => {
        return (
            <Modal show={successMessage} centered size="sm">
                <StyledModalBody>
                    <Text
                        color={Colors.extra.black}
                        fontFamily="Inter"
                        fontWeight="700"
                        fontSize={16}
                        marginTop={0}
                    >
                        Please check your E-mail
                    </Text>
                    <Text
                        color={Colors.extra.black}
                        fontFamily="Inter"
                        fontWeight="400"
                        fontSize={10}
                        marginBottom={0}
                    >
                        Thank you,
                    </Text>
                    <Text
                        color={Colors.extra.black}
                        fontFamily="Inter"
                        fontWeight="400"
                        fontSize={10}
                        marginTop={0}
                    >
                        The C-Light team
                    </Text>
                </StyledModalBody>
            </Modal>
        );
    };

    const onSubmit = useCallback(
        async (
            data: any,
            { setErrors, setSubmitting, resetForm }: FormikHelpers<any>
        ) => {
            dispatch(
                forgetPassword({
                    email: data.email
                })
            )
                .unwrap()
                .then((response: any) => {
                    if (response?.success) {
                        setSuccessMessage(true);
                        setTimeout(() => {
                            setSuccessMessage(false);
                            resetForm({});
                        }, 5000);
                    } else {
                        setSubmitting(false);
                        setErrors({
                            email: response?.message
                        });
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    setErrors({
                        email: 'Something went wrong. Please try again.'
                    });
                });
        },
        [dispatch]
    );

    return (
        <Wrapper>
            <Text
                color={Colors.extra.black}
                fontFamily="Inter"
                fontWeight="700"
                fontSize={16}
                padding={28}
            >
                Forgot password?
            </Text>
            <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount={false}
            >
                {({ values, handleChange, handleSubmit, errors, touched }) => (
                    <Form onSubmit={handleSubmit}>
                        <InputContainer>
                            <InputLabelWrapper>
                                <Text
                                    fontFamily='"Inter"'
                                    fontSize={Size.XXXSmall}
                                    lineHeight="12px"
                                    marginBottom={0}
                                >
                                    Email
                                </Text>
                            </InputLabelWrapper>
                            <StyledInput
                                className="input"
                                name="email"
                                value={values.email}
                                onChange={handleChange('email')}
                            />
                            {touched.email && errors.email && (
                                <Text marginBottom={0} color={Colors.extra.red}>
                                    {errors.email}
                                </Text>
                            )}
                        </InputContainer>

                        <ButtonsWrapper>
                            <Button
                                backgroundColor={Colors.theme.secondary}
                                width="126px"
                                height="42px"
                                isBoxShadow={false}
                                borderRadius="4px"
                                borderColor={Colors.theme.secondary}
                                color={Colors.extra.black}
                                fontSize={Size.XXSmall}
                                fontWeight={600}
                                onClick={() => navigate('/')}
                            >
                                Cancel
                            </Button>
                            <Separator />
                            <Button
                                width="126px"
                                height="42px"
                                isBoxShadow={false}
                                borderRadius="4px"
                                borderColor={Colors.theme.secondary}
                                color={Colors.extra.black}
                                fontSize={Size.XXSmall}
                                fontWeight={600}
                            >
                                Send
                            </Button>
                        </ButtonsWrapper>
                    </Form>
                )}
            </Formik>
            <div ref={modalRef}>
                <ThankyouModal />
            </div>
        </Wrapper>
    );
};

export default ForgotPassword;
