import React, {
    FunctionComponent,
    forwardRef,
    useImperativeHandle,
    useRef
} from 'react';

import { Button, ImageGifViewer, Modal } from 'src/shared';
import { Size } from 'src/shared/text';
import { Colors } from 'src/utils/color';

import { ActionButtons, Container } from './styles';

interface Props {
    ref: React.Ref<HTMLInputElement>;
}

const HealthMonitorModal: FunctionComponent<Props> = forwardRef<any, Props>(
    (props, ref) => {
        const modalRef = useRef<any>(null);

        useImperativeHandle(ref, () => ({
            show: () => {
                modalRef?.current?.show();
            },
            hide: () => {
                modalRef?.current?.hide();
            }
        }));

        return (
            <Modal ref={modalRef}>
                <Container>
                    <ImageGifViewer
                        src={require('src/assets/images/health.png')}
                        type="image"
                    />
                    <ImageGifViewer
                        src={require('src/assets/images/health.png')}
                        type="image"
                    />
                </Container>
                <ActionButtons>
                    <Button
                        className="cancel-button"
                        backgroundColor={Colors.theme.secondary}
                        borderColor={Colors.theme.secondary}
                        width="126px"
                        height="42px"
                        fontSize={Size.XXSmall}
                        fontWeight={600}
                        color={Colors.extra.black}
                        borderRadius="4px"
                        onClick={() => modalRef.current.hide()}
                    >
                        Close
                    </Button>
                </ActionButtons>
            </Modal>
        );
    }
);

export default HealthMonitorModal;
