import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { Colors } from 'src/utils/color';

export const Wrapper = styled.div`
    width: 25%;
    position: absolute;
    top: 25%;
    transform: translateX(-50%);
    left: 50%;

    & .signin-link {
        display: inline;
    }

    @media (max-width: 1024px) {
        width: 60%;
        transform: translateX(-50%);
        left: 50%;
    }

    @media (max-width: 768px) {
        width: 80%;
        transform: translateX(-50%);
        left: 50%;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
`;

export const Separator = styled.div`
    width: 8px;
`;

export const InputContainer = styled.div`
    border: 1px solid black;
    height: 60px;
    position: relative;
    border-radius: 4px;
    margin-bottom: 30px;
`;

export const InputLabelWrapper = styled.span`
    position: absolute;
    top: -7px;
    left: 10px;
    padding: 0px 5px;
    background-color: white;
`;

export const StyledInput = styled.input`
    &:focus {
        border: 1px solid black !important;
    }
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
`;
export const StyledModalBody = styled(Modal.Body)`
    background-color: ${Colors.theme.page_background};
    border-radius: 8px;
`;
