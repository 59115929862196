import { FC } from 'react';
import RS from 'react-switch';

import Text, { Size } from 'src/shared/text';
import { Colors } from 'src/utils/color';

import { Container } from './styled';

interface Props {
    offText: string;
    onText: string;
    value: boolean;
    isDisabled?: boolean;
    onChange: (checked: boolean) => void;
}

const Switch: FC<Props> = (props: Props) => {
    const { offText, onText, value, isDisabled, onChange } = props;
    return (
        <Container>
            <Text
                fontWeight="700"
                fontSize={Size.XXXSmall}
                lineHeight="15px"
                marginRight={10}
                marginTop={15}
            >
                {offText}
            </Text>
            <RS
                onChange={onChange}
                checked={value}
                checkedIcon={false}
                uncheckedIcon={false}
                width={34}
                height={14}
                handleDiameter={20}
                disabled={isDisabled}
                onColor={Colors.switch.track_bg}
                onHandleColor={Colors.theme.primary}
                activeBoxShadow={Colors.switch.handle_shadow}
            />
            <Text
                fontWeight="700"
                fontSize={12}
                lineHeight="15px"
                marginLeft={10}
                marginTop={15}
            >
                {onText}
            </Text>
        </Container>
    );
};

export default Switch;
