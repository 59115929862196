import { FunctionComponent } from 'react';
import { Col } from 'react-bootstrap';

import { Card, CircularProgress, ListItems, Radar } from 'src/shared';
import { SubScanSummary } from 'src/types/scan';

import {
    CircularProgressContainer,
    Container,
    DividerLine,
    LastContainer,
    RadarContainer
} from './styles';

const getNumericDisplayValue = (value?: number, digits: number = 3): string => {
    return value?.toFixed(digits).toString() || 'N/A';
};

type Props = {
    summaryData: SubScanSummary | null;
};

const Summary: FunctionComponent<Props> = ({ summaryData }: Props) => {
    return (
        <Container>
            <Col md={12} lg={4} className="col">
                <CircularProgressContainer>
                    <CircularProgress
                        title="Fixation stability"
                        //data={list}
                    />
                    <DividerLine />
                    <Radar />
                </CircularProgressContainer>
            </Col>
            <Col md={12} lg={4} className="col">
                <RadarContainer>
                    <CircularProgress
                        title="Saccades"
                        //data={list}
                    />
                    <DividerLine />
                    <Radar type="dotted" />
                </RadarContainer>
            </Col>
            <Col md={12} lg={4}>
                <LastContainer>
                    <CircularProgress
                        title="Fixation metrics"
                        //data={list}
                    />
                    <DividerLine />
                    <Card>
                        <ListItems
                            key="summary-1"
                            padding={0}
                            leftContent="Microsaccade frequency"
                            rightContent={getNumericDisplayValue(
                                summaryData?.stats.saccade_frequency_hz
                            )}
                        />
                        <ListItems
                            key="summary-2"
                            padding={0}
                            leftContent="Microsaccade amplitude"
                            rightContent={getNumericDisplayValue(
                                summaryData?.stats.saccade_average_amplitude_deg
                            )}
                        />
                        <ListItems
                            key="summary-3"
                            padding={0}
                            leftContent="Microsaccade peak velocity"
                            rightContent={getNumericDisplayValue(
                                summaryData?.stats
                                    .saccade_average_peak_velocity_degs
                            )}
                        />
                        <ListItems
                            key="summary-4"
                            padding={0}
                            leftContent="Drift velocity"
                            rightContent={getNumericDisplayValue(
                                summaryData?.stats.drift_velocity_degs
                            )}
                        />
                        <ListItems
                            key="summary-5"
                            padding={0}
                            leftContent="Drift ratio"
                            rightContent={getNumericDisplayValue(
                                summaryData?.stats.drift_ratio
                            )}
                        />
                        <ListItems
                            key="summary-6"
                            padding={0}
                            leftContent="Blink count"
                            rightContent={getNumericDisplayValue(
                                summaryData?.stats.blink_count,
                                0
                            )}
                        />
                        <ListItems
                            key="summary-7"
                            padding={0}
                            leftContent="Directional deviation"
                            rightContent={getNumericDisplayValue(
                                summaryData?.stats.directional_deviation
                            )}
                        />
                    </Card>
                </LastContainer>
            </Col>
        </Container>
    );
};

export default Summary;
