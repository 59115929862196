import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Card, ListItems, Text } from 'src/shared';
import { SubScanSummary } from 'src/types/scan';
import { Colors } from 'src/utils/color';

const StyledBox = styled.div`
    padding: 20px 35px 45px 35px;
`;

const Container = styled.div`
    margin-right: 0;
    height: 100%;
`;

type Props = {
    summaryData: SubScanSummary | null;
};

const MetadataCard: FunctionComponent<Props> = ({ summaryData }: Props) => {
    return (
        <Container>
            <Card>
                <StyledBox>
                    <Text
                        textAlign="left"
                        color={Colors.theme.primary}
                        fontWeight="600"
                        fontSize={18}
                    >
                        Summary
                    </Text>
                    <ListItems
                        padding={3}
                        leftContent="Recorded at"
                        rightContent={
                            'N/A'
                        }
                    />
                    <ListItems
                        padding={3}
                        leftContent="Data file ID"
                        rightContent={
                            'N/A'
                        }
                    />
                    <ListItems
                        padding={3}
                        leftContent="Serial #"
                        rightContent={
                            'N/A'
                        }
                    />
                    <ListItems
                        padding={3}
                        leftContent="SW version"
                        rightContent={
                            'N/A'
                        }
                    />
                    <ListItems
                        padding={3}
                        leftContent="Notes"
                        rightContent={
                            'N/A'
                        }
                    />
                    <ListItems
                        padding={3}
                        leftContent="Groups"
                        rightContent={
                            'N/A'
                        }
                    />
                    <ListItems
                        padding={3}
                        leftContent="Tags"
                        rightContent={
                            'N/A'
                        }
                    />
                </StyledBox>
            </Card>
        </Container>
    );
};

export default MetadataCard;
