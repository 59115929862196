import { Form, Formik, FormikHelpers } from 'formik';
import React, {
    FunctionComponent,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef
} from 'react';

import { useAppDispatch } from 'src/hooks';
import { Loader } from 'src/pages/report/components';
import { UserSelectors, changePassword } from 'src/reducers/user';
import { Modal } from 'src/shared';
import {
    ChangePasswordData,
    IChangePasswordModal
} from 'src/types/changePasswordModal';
import { Colors } from 'src/utils/color';

import { CancelButton, ErrorText, Input, SaveButton } from './components';
import { intitalValues, passwordTypes, validationSchema } from './constants';
import { ActionsContainer, Gap, LoaderlWrapper } from './styled';

const ChangePasswordModal: FunctionComponent<IChangePasswordModal> = forwardRef<
    any,
    IChangePasswordModal
>((props: IChangePasswordModal, ref) => {
    const { onSave } = props;
    const dispatch = useAppDispatch();
    const { profile } = UserSelectors();
    const modalRef = useRef<any>(null);

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef?.current?.show();
        },
        hide: () => {
            modalRef?.current?.hide();
        }
    }));

    const handleCancel = () => modalRef?.current?.hide();

    const onSubmit = useCallback(
        (
            data: ChangePasswordData,
            { setErrors, setSubmitting }: FormikHelpers<ChangePasswordData>
        ) => {
            setSubmitting(true);
            dispatch(
                changePassword({
                    email: profile?.email + '',
                    old_password: data.oldPassword,
                    new_password: data.newPassword
                })
            )
                .unwrap()
                .then((response: any) => {
                    setSubmitting(false);
                    if (response?.success) {
                        onSave(data);
                    } else {
                        setErrors({
                            confirmPassword: response?.message
                        });
                    }
                })
                .catch(() => {
                    setSubmitting(false);
                    setErrors({
                        confirmPassword:
                            'Something went wrong. Please try again.'
                    });
                });
        },
        [onSave, dispatch, profile]
    );

    return (
        <Modal
            ref={modalRef}
            title="Change Password"
            bgColor={Colors.extra.modal_bg}
        >
            <Formik
                initialValues={intitalValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                    values,
                    handleSubmit,
                    handleChange,
                    errors,
                    touched,
                    isSubmitting
                }: any) => (
                    <>
                        <Form>
                            {passwordTypes.map((pt, index) => (
                                <React.Fragment key={index}>
                                    <Input
                                        label={pt.label}
                                        value={values[pt.formikKey]}
                                        onChange={handleChange(pt.formikKey)}
                                    />
                                    {touched[pt.formikKey] &&
                                        errors[pt.formikKey] && (
                                            <ErrorText
                                                text={errors[pt.formikKey]}
                                            />
                                        )}
                                    {index !== passwordTypes.length - 1 && (
                                        <Gap y={20} />
                                    )}
                                </React.Fragment>
                            ))}
                            <Gap y={50} />
                        </Form>
                        <ActionsContainer>
                            <CancelButton onClick={handleCancel} />
                            {isSubmitting ? (
                                <LoaderlWrapper>
                                    <Loader />
                                </LoaderlWrapper>
                            ) : (
                                <SaveButton onClick={handleSubmit} />
                            )}
                        </ActionsContainer>
                    </>
                )}
            </Formik>
        </Modal>
    );
});

export default ChangePasswordModal;
